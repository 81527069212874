import fetch from './fetch';
import store from '../store/store';
import transformToConfirmForm from './transformToConfirmForm';
import isBpaContactPlat from './isBpaContactPlat';

function repeat(char, num) {
  return new Array(num + 1).join(char);
}

export function getSize() {
  const { printerLength } = store.getState().user.info.user.extra || {};
  return parseInt(printerLength || '0') || 40;
}

class CmdFormatter {
  constructor(params) {
    this.cmd = [];
    this.p = params;
    this.size = getSize();
  }

  barcode(value) {
    this.cmd.push({
      name: 'barcode',
      param: [value, 3, 1, 'CODE128'],
    });
  }

  command = (name, value) => {
    this.cmd.push({
      name,
      param: typeof value === 'string' ? [value] : value,
    });
  }

  center = (string) => {
    if (!string) {
      return;
    }
    if (string.length > this.size) {
      for (let i = 0; i < string.length; i += this.size) {
        this.center(string.substr(i, this.size));
      }
    } else {
      const length = this.size - string.length;
      this.stringOne(repeat(' ', Math.floor(length / 2)) + string + repeat(' ', Math.floor(length / 2)));
    }
  }

  fill = (char) => {
    this.stringOne(repeat(char, getSize()));
  }

  string = (title, value) => {
    if (typeof value === 'string') {
      value = value.replace('\u200B', '');
    }
    if (!title) {
      return;
    }
    if (!value) {
      for (let i = 0; i < title.length; i += this.size) {
        const sub = title.substr(i, i + this.size);
        this.stringOne(sub);
      }
    } else if (title.length + value.length < this.size) {
      this.stringOne(title + repeat('.', this.size - (title.length + value.length)) + value);
    } else {
      if (title < this.size) {
        this.stringOne(title + repeat('.', this.size - title.length));
      } else {
        for (let j = 0; j < title.length; j += this.size) {
          const sub2 = title.substr(j, this.size);
          this.stringOne(sub2 + repeat('.', this.size - sub2.length));
        }
      }
      if (value.length < this.size) {
        this.stringOne(repeat('.', this.size - value.length) + value);
      } else {
        const skip = this.size - (value.length % this.size);
        const first = value.substr(0, this.size - skip);
        this.stringOne(repeat('.', skip) + first);
        const other = value.substr(this.size - skip);
        for (let l = 0; l < other.length; l += this.size) {
          const sub3 = other.substr(l, this.size);
          this.stringOne(sub3 + repeat('.', this.size - sub3.length));
        }
      }
    }
  }

  // store
  callFiscal = (frModel = '', frPort = '10000', url1c, commands) => fetch(`?model=${frModel}&port=${frPort}`, {
    backendUrl: `${url1c}`,
    prefix: '/execute',
    method: 'POST',
    notJson: true,
    body: [...commands],
    noDefaultNotification: true,
  });

  stringOne = (string) => {
    this.cmd.push({
      name: 'string',
      param: [string],
    });
  }
}

export default CmdFormatter;
export function printFiscalCheque(form, params, center = [], platId) {
  const isBpaContact = isBpaContactPlat(platId);
  const unistream = platId === 'unistream_mt' || platId === 'tinkoff' || platId === 'unistream_cards' || platId === 'unistream_loan';
  const cmd = new CmdFormatter(
    transformToConfirmForm(form, params),
    getSize(),
  );

  const user = store.getState().user.info;

  if (!isBpaContact) {
    cmd.center(user.company.address);
  }
  cmd.command('run', [2]);
  cmd.stringOne(repeat('=', getSize()));
  cmd.center('КАССОВЫЙ ЧЕК');
  cmd.center(form.name);
  cmd.stringOne(repeat('=', getSize()));

  if (params.ADDINFO) {
    cmd.center(params.ADDINFO);
  }
  if (params.addinfo) {
    cmd.center(params.addinfo);
  }
  if (params.chequeText) {
    cmd.center(params.chequeText);
  }
  if (unistream) {
    cmd.center(user.company.name);
    cmd.center(`ИНН: ${user.company.inn}`);
    cmd.center(`Адрес: ${user.company.address}`);
    cmd.center(`Телефон: ${user.company.phone}`);
    cmd.string('Наименование субагента, его ИНН', '');
    cmd.string('Адрес и телефон субагента', '');
  }

  if (center.length > 0) {
    center.forEach((item) => {
      cmd.center(item);
    });
  }
  //print all form

  if (unistream) {
    cmd.string('Страна', 'Россия');
  }

  if (unistream) {
    Object.keys(cmd.p).slice(0, 10).forEach(property => cmd.string(cmd.p[property][0], cmd.p[property][1]));
    cmd.string('Электронная почта', user.company.email);
    Object.keys(cmd.p).slice(10).forEach(property => cmd.string(cmd.p[property][0], cmd.p[property][1]));
  } else {
    Object.keys(cmd.p).forEach(property => cmd.string(cmd.p[property][0], cmd.p[property][1]));
  }
  //
  // for (const property in cmd.p) {
  //   const value = cmd.p[property];
  //   cmd.string(value[0], value[1]);
  // }
  cmd.stringOne(repeat('=', getSize()));
  if (params.bpa_fee) {
    cmd.center(`Вознаграждение агента:${params.bpa_fee} ${params.bpa_fee_cur || 'руб.'}`);
  }

  if (!isBpaContact) {
    cmd.center('ИНН Организации');
    cmd.center(user.company.inn);
  } else {
    cmd.center('Оператор по переводу денежных средств: КИВИ Банк (АО)');
    cmd.center('117648, г. Москва, мкр. Чертаново Северное, д. 1А, корп. 1');
    cmd.center('Тел.: +7 (495) 231-36-45');
    cmd.center('ИНН оператора перевода 3123011520');
    cmd.center('C информацией об условиях и порядке осуществления Перевода можно ознакомится в офисах БПА');
  }


  cmd.center('Адрес места приема денежных ср-в');
  cmd.center(user.user.outletAddress);
  if (unistream) {
    cmd.fill('#');
    cmd.center('С Условиями осуществления денежных');
    cmd.center('переводов');
    cmd.center('по Международной платежной системе');
    cmd.center('денежных переводов');
    cmd.center('«ЮНИСТРИМ» согласен.');
    cmd.fill('#');
    cmd.center('Телефон службы поддержки клиентов');
    cmd.center('АО КБ "ЮНИСТРИМ":');
    cmd.center('8 495 744 55 55 (круглосуточно)');
  }

  if (unistream) {
    cmd.center(`Телефон службы поддержки клиентов агента: ${user.user.phone}`);
    cmd.center(`Электронная почта: ${user.company.email}`);
  } else {
    cmd.center(`Телефон: ${user.user.phone}`);
  }

  if (!isBpaContact) {
    cmd.center(`Кассир: ${user.user.fullName}`);
  }
  cmd.command('open_cheque', []);
  cmd.command('sale', [
    1000,
    params.full_amount,
    repeat(' ', getSize()),
  ]);
  cmd.command('close_cheque', [
    params.full_amount,
    repeat(' ', getSize()),
  ]);


  return cmd;
}
