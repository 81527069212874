// import expressions from 'angular-expressions';
import notification from 'antd/lib/notification';
import classnames from 'classnames';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import React from 'react';
import { Field } from 'react-final-form';
import { Link } from 'react-router-dom';

import ArrowDown from '../../../assets/img/icons/arrow-down.svg';
import Close from '../../../assets/svg/close-black.svg';
import TxDataField from '../../../components/common/TxDataField';
import Button from '../../../components/ui/Button';
import ConditionalTooltip from '../../../components/ui/ConditionalTooltip';
import Modal from '../../../components/ui/Modal';
import ProductLogo from '../../../components/ui/ProductLogo';
import ValidateInput from '../../../components/ui/ValidateInput';
import { ISO_VALUE } from '../../../constants/currencies';
import { animate, quad } from '../../../utils/animations';
import CmdFormatter, { printFiscalCheque } from '../../../utils/CmdFormatter';
import { downloadFiles } from '../../../utils/downloadFiles';
import fetch from '../../../utils/fetch';
import logger from '../../../utils/logger';
import { sendNotify } from '../../../utils/processing';
import { merchantCheck, payment } from '../../../utils/processingCalls';
import transformToConfirmForm from '../../../utils/transformToConfirmForm';
import ErrorStatus from '../assets/svg/error-status.svg';
import SuccessStatus from '../assets/svg/success-status.svg';
import Address from './address/Address';
import Error from './ErrorTooltip';
import AmountFinalFieldWrap from './final-form-inputs/AmountFinalFieldWrap';
import AutocompleteFinalFieldWrap from './final-form-inputs/AutocompleteFinalFieldWrap';
import CardnumberFinalFieldWrap from './final-form-inputs/CardnumberFinalFieldWrap';
import CheckboxFinalFieldWrap from './final-form-inputs/CheckboxFinalFieldWrap';
import Condition from './final-form-inputs/Condition';
import DateFinalFieldWrap from './final-form-inputs/DateFinalFieldWrap';
import DocumentFinalFieldWrap from './final-form-inputs/DocumentFinalFieldWrap';
import FileInputFinalFieldWrap from './final-form-inputs/FileInputFinalFieldWrap';
import PhoneFinalFieldWrap from './final-form-inputs/PhoneFinalFieldWrap';
import PromocodeFinalField from './final-form-inputs/PromocodeFinalField';
import RadioFinalFieldWrap from './final-form-inputs/RadioFinalFieldWrap';
import LeftFillSteps from './LeftFillSteps';
import RightBillingInfo from './RightBillingInfo';
import SubmitEasySale from './SubmitEasySale';
import TemplatesBlock from './TemplatesBlock';
import Wizard from './Wizard';
import PreventFormFill from './PreventFormFill';
import MaskInput from '../../../components/ui/MaskInput';
import FinalFormPropsWrapper from './final-form-inputs/FinalFormPropsWrapper';
import FileInput from '../../../components/ui/FileInput';
import { setActiveCartItem, updateCartData } from '../../../actions/cart';
import isBpaContactPlat from '../../../utils/isBpaContactPlat';

const onbeforeunload = (event) => {
  const message = 'На странице есть поля ввода, Вы уверены, что хотите покинуть страницу?';
  const e = event || window.event;
  // For IE and Firefox
  if (e) {
    e.returnValue = message;
  }

  // For Safari
  return message;
};

const fetchTxsByTimeAndGuid = (complexSalesGuid) => {
  const start = moment().subtract(1, 'd').format('x');
  const finish = moment().add(1, 'd').format('x');

  return fetch(`/complex/sales/txs?finish_t=${finish}&start_t=${start}&complexSalesGuid=${complexSalesGuid}`, { method: 'GET', noDefaultNotification: true });
};

const sendT2marketplaceForm = (authToken, value) => fetch('/eyeline/sendForm', {
  method: 'POST',
  body: {
    method: 'sendForm',
    authToken,
    value,
  },
});

const getUnique = (arr, comp) => {
  const unique = arr
    .map(e => e[comp])
    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    // eliminate the dead keys & store unique objects
    .filter(e => arr[e]).map(e => arr[e]);

  return unique;
};

const windowCloseWithNotify = function () {
  window.close();
};

String.prototype.replaceAt = function replaceAt(index, replacement) {
  return this.substr(0, index) + replacement + this.substr(index + replacement.length);
};

const amountFieldNames = ['amount', 'comission', 'full_amount'];
const documentFieldNames = ['id_series', 'id_number'];
const kostilDisabledFieldNames = ['comission', 'full_amount'];

const FIXED_BLOCKS_OFFSET_TOP = 40;
const COMMISSION_DEBOUNCE_TIME = 4500;

const makeCancelable = (promise) => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error)),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    },
  };
};

const returnFirstNonEmptyObject = (...args) => {
  if (args.length === 0) {
    return {};
  }
  const returningobj = args.find((arg) => {
    if (Object.keys(arg).length !== 0) {
      return arg;
    }
    return false;
  });
  return returningobj || {};
};

const filterImportantFields = f => typeof f.required === 'undefined' || f.required === true || f.required === 'true' || f.field_name === 'amount' || f.field_name === 'comission' || f.field_name === 'full_amount';

const checkBotAndFix = (block, formFillHeight, formFillBottomCoord, headerWithAlertHeight) => {
  const scrollY = window.scrollY || window.pageYOffset || document.body.scrollTop;

  if (scrollY + block.element.clientHeight + FIXED_BLOCKS_OFFSET_TOP + headerWithAlertHeight < formFillBottomCoord) {
    if (!block.isFixed) {
      block.element.style.position = 'fixed';
      block.element.style.right = 'auto';
      block.element.style.left = `${block.offsetLeft}px`;
      block.element.style.top = `${FIXED_BLOCKS_OFFSET_TOP + headerWithAlertHeight}px`;
      block.isFixed = true;
    }
    if (block.element.style.left !== block.offsetLeft) {
      block.element.style.left = `${block.offsetLeft}px`;
    }
  } else {
    const topVal = formFillHeight - block.element.clientHeight + 1; // border (?)

    if (block.isFixed) {
      block.element.style.position = '';
      block.element.style.right = '';
      block.element.style.left = '';
      block.element.style.top = `${topVal >= 0 ? topVal : 0}px`;
      block.isFixed = false;
    }
    block.element.style.top = `${topVal >= 0 ? topVal : 0}px`;
  }
};

let restPromise = Promise.resolve();
let updateByFetch = false;
let scrollX;

const noop = () => {};

const updateRestPromise = (promise) => { restPromise = promise; };

const isBpaSubstrings = ['mgts', 'ktelecom', 'dale_telecom', 'matrix', '2kom', 'ntvplus', 'cardtel', 'xplat_lifeline', 'topup_rostelekom', '_xplat'];

export default class CatalogFormFill extends React.PureComponent {
  constructor(props) {
    super(props);

    let defaultState = {
      resetParameter: 0,
      combo_sets: [],
      visible: {},
      passToOneC: {},
      showAmountFieldNames: true,
      loyaltyCode: '',
      formSubmitted: false,
      saleTypeMissmatch: false,
      groups: [],
      loyaltyInputName: 'Номер телефона клиента (не обязательно)',
      additionalFormOnPrechecque: [],
      valuesAfterPrechecque: {},
      stepValid: false,
      formDefaultValues: {},
      disabledFields: {},
      values: {},
      dstExtra: {},
      txId: '',
      multipleSaleAvailable: false,
      currentStep: 1,
      selectedTxTemplate: null,
      formError: false,
      formErrorMessage: '',
      startedTxId: '',
      phase: 'fill',
      calcSuccess: false,
      currentCat: '',
      form: {},
      change: () => {},
      selectedCountryIcon: false,
      vskDiscount: '',
      realDiscount: '',
      complexPlusBarcodeLoading: false,
      isAsyncValidationInProgress: false,
      vskDmsSuggestModalActive: false,
    };

    defaultState.draw = '';

    //side logic
    if (props.externalForm && props.externalForm.formData && Object.keys(props.externalForm.formData).length > 0) {
      defaultState.dst_amount = this.props.externalForm.formData.amountWithCommission;
      defaultState.full_amount = this.props.externalForm.formData.amount;
    }

    if (Object.keys(this.props.externalForm.form).length > 0) {
      const newStateParams = this.takeReduxFormDataAndAppendToState();
      defaultState = { ...defaultState, ...newStateParams };
    }

    this.state = defaultState;

    this.header = null;
    this.headerWithAlert = null;
    this.headerContainer = null;
    this.headerHeight = 80;
    this.pageContainer = null;
    this.leftBlock = {
      isFixed: false,
      offsetLeft: 0,
      element: null,
    };
    this.rightBlock = {
      isFixed: false,
      offsetLeft: 0,
      element: null,
    };
    this.formFillTopOffset = 0;
    this.requested = false;
    this.resetIndex = 0;
    this.initialDisabledFields = {};
  }

  componentDidCatch(error, info) {
    // logger({ message: 'Тестим', data: { error, info }, level: 'info', tag: 'heh' });
  }

  componentDidMount() {
    // - fixed-scroll sideblocks -
    this.headerWithAlert = window.document.getElementsByClassName('header-and-alerts-wrap')[0];
    //eslint-disable-next-line
    this.header = window.document.getElementsByClassName('header')[0];
    //eslint-disable-next-line
    this.headerContainer = this.header.getElementsByClassName('container')[0];

    // eslint-disable-next-line
    this._isMounted = true;
    window.addEventListener('scroll', this.onScroll, true);
    window.addEventListener('resize', this.onResize, false);

    window.onbeforeunload = onbeforeunload;

    if (this.props.match.params.category && Object.keys(this.props.externalForm.form).length === 0) {
      this.fetchFormDataAndAppendToState();
    }

    if (Object.keys(this.props.externalForm.form).length > 0) {
      // this.takeReduxFormDataAndAppendToState();
      this.props.updatePageLoading({ pageLoading: false });
    }

    restPromise.then(() => {
      if (this.state.stepValid === true
        && this.state.pageIndex === 0
      ) {
        let amountForCalc = -1;
        let typeOfCalc = 'out';
        //todo Место для определения типа in out
        if (this.state.values.amount) {
          amountForCalc = this.state.values.amount;
          typeOfCalc = 'out';
        }
        this.debouncingComission(amountForCalc, typeOfCalc, this.state.values.currency, this.state.values);
      }
    });
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   // if ((JSON.stringify(nextState) === JSON.stringify(this.state))) { //todo узко
  //   //   return false;
  //   // }
  //   return true;
  // }

  componentDidUpdate(prevProps, prevState) {
    const valuesEqual = isEqual(this.state.values, prevState.values);

    // if (prevState.currentStep !== 4 && this.state.currentStep === 4) {
    //   const complexSalesGuid = window.sessionStorage.getItem('complexSalesGuid');
    //   fetchTxsByTimeAndGuid(complexSalesGuid)
    //     .then((result) => {
    //       console.log('result ', result);
    //       const txs = result.result.map(tx => tx.gateTx).toString(',');
    //
    //       fetch(`/merchant/cashback_check?txs=${txs}&complexSalesGuid=${complexSalesGuid}`, { method: 'GET', noDefaultNotification: true })
    //         .then((comboResults) => {
    //           this.setState({
    //             combo_sets: comboResults.result.combo_sets,
    //           });
    //         });
    //     });
    // }

    if (prevProps.pageLoading && !this.props.pageLoading) {
      this.getOffsets();
      this.updateState();
    }

    if (prevState.phase !== this.state.phase) {
      this.animatedScrollToTop();
    }

    if (!valuesEqual || Object.keys(prevState.visible).length === 0) {
      const visible = this.activeFieldsChecker();
      // const visible = Object.keys(visibleDirty).reduce((acc, curKey) => {
      //   acc[curKey] = !!visibleDirty[curKey];
      //   return acc;
      // }, {});
      if (!isEqual(prevState.visible, visible)) { //todo рефактор на что-то вменяемое
        //eslint-disable-next-line
        this.setState({ visible });
      }
    }

    if (prevState.fetchingAmount !== this.state.fetchingAmount || updateByFetch) {
      return;
    }

    if (prevState.pageIndex !== this.state.pageIndex) {
      window.scrollTo(0, 0);
    }

    if (!valuesEqual) {
      const { values, formDefaultValues } = this.state;
      const defaultKeys = Object.keys(formDefaultValues);
      const anyFieldTouched = Object.keys(values).filter(key => !defaultKeys.includes(key)).some(key => !!values[key]);
      this.props.updateOpenModalBeforeLeave(anyFieldTouched);
      window.onbeforeunload = anyFieldTouched ? onbeforeunload : undefined;
    }

    if (
      this.state.pageIndex === 0
      && ((this.state.stepValid === true
      && !valuesEqual)
      || (prevState.stepValid === false && this.state.stepValid === true && valuesEqual))
    ) {
      //eslint-disable-next-line
      this.setState({ calcSuccess: false });
      let amountForCalc = -1;
      let typeOfCalc = 'out';
      //todo Место для определения типа in out
      if (this.state.values.amount) {
        amountForCalc = this.state.values.amount;
        typeOfCalc = 'out';
      }
      this.debouncingComission(amountForCalc, typeOfCalc, this.state.values.currency, this.state.values);
    } else if (this.state.pageIndex === 0 && !this.state.stepValid) {
      // this.resetCommission();
    }
  }


  componentWillUnmount() {
    this.turnOffBeforeUnload();
    this.props.removeTransaction();
    this.props.removeMarketplaceData();
    // eslint-disable-next-line
    this._isMounted = false;
    window.removeEventListener('scroll', this.onScroll, true);
    window.removeEventListener('resize', this.onResize, false);
    this.props.updatePageLoading({ pageLoading: true });
  }

  cancelableComission = null;

  debouncingComission = debounce((a, b, c, d) => {
    if (this.cancelDebouncingCalcFlag) {
      this.cancelDebouncingCalcFlag = false;
      return;
    }

    if (this.cancelableComission) {
      this.cancelableComission.cancel();
    }

    const { platId } = this.state;
    this.cancelableComission = platId === 'pet' ? makeCancelable(restPromise.then(() => this.calcCommission(a, b, c, d))) : makeCancelable(this.calcCommission(a, b, c, d));
    this.cancelableComission
      .promise
      .then((result) => {
        // form.batch(() => {
        //   form.change('firstName', 'Erik') // listeners not notified
        //   form.change('lastName', 'Rasmussen') // listeners not notified
        // })
        updateByFetch = true;
        this.state.change('full_amount', result.full_amount);
        this.state.change('amount', result.dst_amount);
        this.state.change('comission', result.comission);
        this.setState({
          ...result,
          calcData: result,
          calcSuccess: true,
        }, () => {
          updateByFetch = false;
        });
      }).catch((e) => {
        // this.setState({
        //   dst_amount: undefined,
        //   full_amount: undefined,
        //   amount: undefined,
        //   comission: undefined,
        //   dst_amount_rur: undefined,
        //   trnRate: undefined,
        //   rate: undefined,
        // })
        this.resetCommission();
      // dst_amount: result.dst_payment.amount,
      //   full_amount: result.src_payment.amount,
      //   // amount: result.dst_payment.amount === -1 ? result.src_payment.amount : result.dst_payment.amount,
      //   amount: result.dst_payment.amount,
      //   comission: result.dst_payment.amount === -1 ? 0 : result.src_payment.amount - Math.round(result.dst_payment.amount * rate),
      //   dst_amount_rur: Math.round(result.dst_payment.amount * rate * 100) / 100,
      //
      //   trnRate: result.rate || 1,
      //   rate: result.rate || 1,
      });
  }, COMMISSION_DEBOUNCE_TIME, {
    leading: false,
    trailing: true,
  });

  reverseAndReduce = (arr, cb) => arr.reverse().reduce(cb, []);

  fetchFormDataAndAppendToState = () => {
    Promise.all([fetch(`/categories/${this.props.match.params.category}`), this.props.getCats('root')])
      .then((results) => {
        const originalFields = results[0].result;
        fetch(`/categories/render_groups/${this.props.match.params.category}`)
          .then((result) => {
            const currentCat = this.curFormName(originalFields.cat);
            // if (this.props.match.params.category === 'unistream_mt') {
            //   result.result.renderGroups = unistreamTemplate.groupNewKass;
            // }

            const { passToOneC, fieldByQueryParam } = result.result;
            //todo добавить флаги для прокидывания значений направо в блок инфы+добавить хедер информация о переводе
            if (typeof result.result.renderGroups !== 'undefined' && result.result.renderGroups.length !== 0) {
              const flatGroups = originalFields.group.reduce((a, b) => a.concat(b.fields), []);
              const newGroups = result.result.renderGroups.map((group) => {
                // const fields = group.fields.map((field) => {
                const fields = group.fields.filter(filterImportantFields).map((field) => {
                  //todo привести к единообразию renderGroups
                  const originalField = flatGroups.find(flatGroupItem => flatGroupItem.field_name === field.id || flatGroupItem.field_name === field.field_name);
                  const isFieldNameNumber = !Number.isNaN(parseInt(field.field_name, 10)) && typeof parseInt(field.field_name, 10) === 'number';
                  const field_name = isFieldNameNumber ? `_${field.field_name}` : field.field_name;

                  if (originalField) {
                    if (typeof field.visuals !== 'undefined') {
                      field.visual = field.visuals;
                    }
                    return { ...originalField, ...field, field_name };
                  }
                  if (typeof field.visuals !== 'undefined') {
                    field.visual = field.visuals;
                  }
                  return { ...field, field_name };
                }).filter(el => el != null);

                return { ...group, fields };
              });

              const infoBlocks = result.result.infoBlocks || {};
              const withTemplates = result.result.withTemplates || false;
              const ignoreLastEasySalesSteps = result.result.ignoreLastEasySalesSteps || false;
              const additionalFormOnPrechecque = result.result.additionalFormOnPrechecque || [];
              const loyaltyInputName = result.result.loyaltyInputName || 'Номер телефона клиента (не обязательно)';

              const formDefaultValues = {};
              newGroups.forEach((group) => {
                group.fields.forEach((field) => {
                  if (field.default) {
                    formDefaultValues[field.field_name] = field.default;
                  }
                });
              });
              const { saleType, redirect } = result.result;
              const disabledFields = {};
              if (fieldByQueryParam) {
                // console.log('fieldByQueryParam ', fieldByQueryParam);
                Object.keys(fieldByQueryParam).forEach((fieldFromUri) => {
                  const ssItem = window.sessionStorage.getItem(fieldFromUri);
                  if (ssItem !== null && ssItem !== '') {
                    const fieldReferenceObject = fieldByQueryParam[fieldFromUri];

                    const fieldReference = fieldReferenceObject.fieldname || fieldReferenceObject.fieldName || fieldReferenceObject.field_name;
                    if (fieldFromUri === 'price') {
                      formDefaultValues[fieldReference] = ssItem.replace(',', '.') * 100;
                    } else {
                      formDefaultValues[fieldReference] = ssItem;
                    }
                    if (fieldReferenceObject.disabled) {
                      disabledFields[fieldReference] = true;
                    }
                  }
                });
              }

              if (originalFields.id === 'drugie_pravila') {
                // formDefaultValues.client_mobile_number = '79771038901';
                // this.t2marketplaceAuthToken = 'eyJhbGciOiJIUzI1NiJ9.eyJwb3MuZGVhbGVySWQiOiJEZWFsZXJUZXN0SWQiLCJwcm9maWxlLnV1aWQiOiIzMzIzMDY3YS0xOWMwLTRjNDktOTQ4ZS02MWMxN2JkMTk1NzgiLCJhcGkuY2xpZW50LmlkIjoidGVrby50ZXN0IiwiaXNzIjoibWItY2xpIiwicG9zLnBvc0lkIjoiNzc1ODY3IiwiaWF0IjoxNTYzODcwMTI4LCJwb3MudXNlcklkIjoiVEVMRTIifQ.g43ySRgNepldgIQErC03wxE9aQWeY4kPfgwTeG3Aq1g';
                if (this.props.t2marketplace.phone) {
                  formDefaultValues.client_mobile_number = this.props.t2marketplace.phone;
                  this.t2marketplaceAuthToken = this.props.t2marketplace.authToken;
                } else {
                  return this.props.history.replace('/sms-auth/drugie_pravila');
                }
              }

              let saleTypeMissmatch = false;
              if (saleType) {
                // "easy", "complex",
                const csg = window.sessionStorage.getItem('complexSalesGuid');
                if (saleType === 'easy') {
                  if (csg && csg !== '0') {
                    saleTypeMissmatch = true;
                  }
                }
                if (saleType === 'complex') {
                  if (!csg || csg === '0') {
                    saleTypeMissmatch = true;
                  }
                }
              }

              this.initialDisabledFields = disabledFields;


              this.setState({
                formDefaultValues,
                additionalFormOnPrechecque,
                currentCat,
                saleTypeMissmatch,
                form: originalFields,
                passToOneC: passToOneC || {},
                redirect,
                groups: newGroups,
                // platName: originalFields.name,
                platId: originalFields.id,
                loyaltyInputName,
                infoBlocks,
                withTemplates,
                ignoreLastEasySalesSteps,
                disabledFields,
                finishStepConfig: result.result.finishStepConfig,
                multipleSaleAvailable: result.result.multipleSaleAvailable || false,
              }, () => {
                this.props.updatePageLoading({ pageLoading: false });
                // this.getOffsets();
                // this.updateState();
              });
            } else {
              //todo убрать отступы(т.к. присутствуют дефольные)
              const clearOriginalFields = this.compressGroups(originalFields.group, false);

              const withTemplates = result.result.withTemplates || false;

              this.setState({
                currentCat,
                withTemplates,
                form: originalFields,
                platId: originalFields.id,
                passToOneC: passToOneC || {},
                groups: [{
                  id: 'common',
                  name: 'Оформление платежа',
                  fields: clearOriginalFields,
                  // fields: originalFields.group.reduce((a, b) => a.concat(b.fields), []),
                }],
                // platName: originalFields.name,
                multipleSaleAvailable: result.result.multipleSaleAvailable || false,
              }, () => {
                this.props.updatePageLoading({ pageLoading: false });
                // this.getOffsets();
                // this.updateState();
              });
            }
          })
          .catch(() => {
            const currentCat = this.curFormName(originalFields.cat);
            const amountGrop = originalFields.group.shift();
            const flatGroups = originalFields.group.reduce((a, b) => a.concat(b.fields), []);
            // newGroups
            amountGrop.fields[1].disabled = true;
            amountGrop.fields[2].disabled = true;
            this.setState({
              currentCat,
              form: originalFields,
              groups: [{
                id: 'common',
                name: 'Оформление платежа',
                fields: flatGroups.concat(amountGrop.fields),
              }],
              // platName: originalFields.name,
              platId: originalFields.id,
            }, () => {
              this.props.updatePageLoading({ pageLoading: false });
              // this.getOffsets();
              // this.updateState();
            });
          });
      })
      .catch((e) => {
        console.warn(e);
        this.props.history.replace('/404');
      });
  };

  compressGroups = (originalFieldsGroup, makeIdentityConditionBasedOnAmount = false) => this.reverseAndReduce(originalFieldsGroup, (a, b) => {
    // const clearOriginalFields = originalFields.group.reverse().reduce((a, b) => {
    const newFields = b.fields.filter(filterImportantFields).map((field) => {
      const isFieldNameNumber = !Number.isNaN(parseInt(field.field_name, 10)) && typeof parseInt(field.field_name, 10) === 'number';
      const field_name = isFieldNameNumber ? `_${field.field_name}` : field.field_name;

      if (makeIdentityConditionBasedOnAmount && b.id === 'identity') {
        field.condition = 'amount > 1500000';
      }

      if (field.visual) {
        const { size } = field.visual;
        if (size < 35) {
          return { ...field, visual: { size: 33.3, off: 0, post: 0 }, field_name };
        } if (size >= 35 && size < 55) {
          return { ...field, visual: { size: 50, off: 0, post: 0 }, field_name };
        }
        return { ...field, visual: { size: 100, off: 0, post: 0 }, field_name };
      }

      return { ...field, field_name };
    });
    return a.concat(newFields);
  });

  takeReduxFormDataAndAppendToState = () => {
    const originalFields = this.props.externalForm.form;
    const clearOriginalFields = this.compressGroups(originalFields.group, true);

    return {
      form: originalFields,
      platId: originalFields.id,
      showAmountFieldNames: false,
      groups: [{
        id: 'common',
        name: 'Оформление платежа',
        // fields: clearOriginalFields,
        fields: getUnique(clearOriginalFields, 'field_name'),
        // fields: originalFields.group.reduce((a, b) => a.concat(b.fields), []),
      }],
      // platName: originalFields.name,
      multipleSaleAvailable: false,
    };
  };

  // onPageLeave = () => this.props.history.goBack();//todo переделать на возврат в глобальную категорию

  onPageLeave = (url) => {
    const { ui } = this.props;
    const { form } = this.state;
    const urlToGo = url || `/catalog/${form.cat}`;

    if (ui.openModalBeforeLeave) {
      this.props.openConfirmLeaveModal(urlToGo);
    } else {
      this.props.history.push(urlToGo);
    }
  };

  onBreadcrumbBackToCatClick = () => this.onPageLeave();

  onScroll = () => {
    if (this.requested) return;
    this.requested = true;

    window.requestAnimationFrame(() => {
      this.requested = false;
      this.updateState();
    });
  };

  onResize = () => {
    this.getOffsets();
    this.updateState();
  };

  onConfirmCancelClick = () => {
    const { startedTxId } = this.state;
    const { ui } = this.props;

    this.props.closeConfirmLeaveModal();

    const updateHistory = () => {
      if (ui.url) {
        this.props.history.push(ui.url);
      } else {
        this.props.history.goBack();
      }
    };

    if (startedTxId) {
      fetch('/merchant/cancel', {
        method: 'POST',
        body: { tx: startedTxId, formData: { } },
        withOverlay: true,
        overlayMessage: 'Выполняется отмена транзакции, пожалуйста, подождите',
        overlayMessageDefaultShown: true,
        // overlayMessageShowTime: 1500,
      }).then(() => {
        logger({ message: 'Отмена транзакции до оплаты', data: { tx: startedTxId }, level: 'info', tag: 'cancelTx' });
        updateHistory();
      });
    } else {
      updateHistory();
    }
  };

  onRefuseCancelClick = () => this.props.closeConfirmLeaveModal();

  onBackdropClick = () => this.props.closeConfirmLeaveModal();

  onDeclineVskDmsSuggest = () => {
    this.setState({ vskDmsSuggestModalActive: false });
    if (this.vskDmsAfterNotifyPromiseResolve) {
      this.vskDmsAfterNotifyPromiseResolve();
    }
  };

  onConfirmVskDmsSuggest = () => {
    // for second notify if necessary
    // sessionStorage.setItem('prevProductSendNotifyData', JSON.stringify({ coreObjectToOneC, additionalObjectToOneC, platId }));
    this.setState({ vskDmsSuggestModalActive: false });
    if (this.vskDmsAfterNotifyPromiseReject) {
      this.vskDmsAfterNotifyPromiseReject();
    }
    this.props.history.push('/reload-catalog-form-fill/migrants_support');
  };

  setIsAsyncValidationInProgress = isAsyncValidationInProgress => this.setState({ isAsyncValidationInProgress });

  getOffsets = () => {
    if (!this.header || !this.headerContainer || !this.formFillContent || !this.rightBlock.element) return;
    this.headerHeight = this.header.clientHeight;
    const pageBoundingClientRect = this.pageContainer.getBoundingClientRect();
    this.formFillTopOffset = this.formFillContent.getBoundingClientRect().top + (window.pageYOffset || document.body.scrollTop);
    this.leftBlock.offsetLeft = pageBoundingClientRect.left + 30; // 30 - paddingLeft of container
    this.rightBlock.offsetLeft = (pageBoundingClientRect.left + pageBoundingClientRect.width) - this.rightBlock.element.clientWidth - 30;
    // 30 - paddingRight of container
  };

  goBack = () => {
    this.setState({ phase: 'fill' });
  };

  animatedScrollToTop = () => {
    const scrollY = window.scrollY || window.pageYOffset || document.body.scrollTop;

    animate({
      duration: 450,
      timing: quad,
      draw: (progress) => {
        window.scrollTo(0, scrollY + ((0 - scrollY) * progress));
      },
    }).then(() => {
      this.updateState();
    });
  };

  updateState = () => {
    // eslint-disable-next-line
    if (!this.formFillContent || !this.headerWithAlert || !this._isMounted) return;
    if (window.scrollX !== scrollX) {
      scrollX = window.scrollX;
      this.getOffsets();
    }
    const scrollY = window.scrollY || window.pageYOffset || document.body.scrollTop;
    const formFillHeight = this.formFillContent.clientHeight;
    const formFillBottomCoord = this.formFillTopOffset + formFillHeight;
    const headerWithAlertHeight = this.headerWithAlert.clientHeight;

    if (scrollY > 0) {
      checkBotAndFix(this.leftBlock, formFillHeight, formFillBottomCoord, headerWithAlertHeight);
      checkBotAndFix(this.rightBlock, formFillHeight, formFillBottomCoord, headerWithAlertHeight);
    } else {
      [this.leftBlock, this.rightBlock].forEach((block) => {
        block.element.style.position = '';
        block.element.style.right = '';
        block.element.style.left = '';
        block.element.style.top = '';
        block.isFixed = false;
      });
    }
  };

  calcCommission = (amount, amountType, currency = { value: 'RUB' }, values) => {
    const newValues = Object.keys(values).reduce((acc, key) => {
      if (key[0] === '_') {
        acc[key.substr(1)] = values[key];
      } else {
        acc[key] = values[key];
      }
      return acc;
    }, {});

    return fetch('/merchant/calc', {
      method: 'POST',
      body: {
        product: this.state.form.id,
        amount,
        currency: +ISO_VALUE[typeof currency !== 'object' ? currency : currency.value],
        src_cls: 'cash',
        extra: { amount_type: amountType, ...newValues },
        noDefaultNotification: true,
      },
      withOverlay: true,
      // overlayMessageShowTime: 5000,
      // overlayMessage: 'Ваша операция заняла больше времени, чем ожидалось. Осталось совсем немного!',
    }).then(({ result, code }) => {
      logger({
        message: 'Успешный подсчет комиссии',
        data: {
          product: this.state.form.id,
          amount,
          result,
          currency: +ISO_VALUE[typeof currency !== 'object' ? currency : currency.value],
          src_cls: 'cash',
          extra: { amount_type: amountType, ...values },
          noDefaultNotification: true,
        },
        level: 'info',
        tag: 'calc_success',
      });
      if (code) {
        throw code;
      }
      const rate = result.rate || 1;
      return { //todo доразбираться с этим
        // dst_amount: result.dst_payment.amount === -1 ? result.src_payment.amount : result.dst_payment.amount,
        dst_amount: result.dst_payment.amount,
        full_amount: result.src_payment.amount,
        // amount: result.dst_payment.amount === -1 ? result.src_payment.amount : result.dst_payment.amount,
        amount: result.dst_payment.amount,
        comission: (result.dst_payment.amount === -1 || values.dst_amount === -1) ? 0 : result.src_payment.amount - Math.round(result.dst_payment.amount * rate),
        dst_amount_rur: Math.round(result.dst_payment.amount * rate * 100) / 100,

        trnRate: result.rate || 1,
        rate: result.rate || 1,
      };
    });
  };

  curFormName = (cat) => {
    const { catalog } = this.props;
    if (catalog.cats.root) {
      const curForm = catalog.cats.root.byId[cat];
      if (typeof curForm !== 'undefined') {
        return curForm.name;
      }
    }
    return '';
  };

  updateDisabledFields = (fieldToBlock, val) => {
    this.setState(prevState => ({
      disabledFields: {
        ...prevState.disabledFields,
        [fieldToBlock]: !!val,
      },
    }));
  };

  notNullValidator = value => (value === '' ? 'Обязательно для заполнения' : '');

  tossFormPropertiesUp = (props) => {
    this.setState(props);
  };

  validation = (value, item) => {
    if ((typeof item.notRequired !== 'undefined' && item.notRequired === true) || !item.required) {
      return undefined;
    }

    if (item.type === 'date') {
      if (moment(value).local().isBefore('1900-01-01') || moment(value).local().isAfter('2100-01-01') || isNaN(value)) {
        return 'Введите корректную дату';
      }
      if (moment(value).format('DD-MM-YYYY') === '01-01-1970') {
        return '';
      }
    }

    if (item.type === 'number') {
      if (item.minValue && value < item.minValue) {
        return 'Введенное значение меньше минимально допустимого';
      }
      if (item.maxValue && value > item.maxValue) {
        return 'Введенное значение выше максимально допустимого';
      }
    }

    if (!value) {
      return 'Поле обязательно для заполнения';
    }

    const { reg_ex } = item;

    if (reg_ex && reg_ex !== '') {
      const reg = typeof reg_ex === 'object' ? new RegExp(reg_ex.pattern, reg_ex.flags) : new RegExp(reg_ex);
      const regRaw = typeof reg_ex === 'object' ? `/${reg_ex.pattern}/${reg_ex.flags}` : `/${reg_ex}/`;
      // const regExample = typeof reg_ex === 'object' ? new RandExp(reg_ex.pattern, reg_ex.flags) : new RandExp(reg_ex);
      if (!reg.test(value)) return item.comment || `Введенное значение не соответствует маске <span class="regexp-error-hint">${regRaw}</span>`;
      //<span class="regexp-error-hint-example">(# ${regExample.gen()})</span>
    }

    return '';
  };

  activeFieldsChecker = () => {
    const mapka = this.state.groups.reduce((res, group) => res.concat(group.fields), []);

    const dst_extras = this.state.values;
    let string = '';
    mapka.forEach((element) => {
      if (!Number.isNaN(parseInt(element.field_name, 10)) && typeof parseInt(element.field_name, 10) === 'number') return;
      string += ` var ${element.field_name}; `;
    });

    Object.keys(dst_extras).forEach((key) => {
      if (!Number.isNaN(parseInt(key, 10)) && typeof parseInt(key, 10) === 'number') return;
      if (typeof dst_extras[key] === 'number') {
        string += `var ${key} = ${dst_extras[key]}; `;
      } else if (typeof dst_extras[key] === 'string') {
        string += `var ${key} = ${JSON.stringify(dst_extras[key])}; `;
      } else if (typeof dst_extras[key] === 'object') {
        // string += convertToJsString(string, dst_extras[key]);
        string += `var ${key} = ${JSON.stringify(dst_extras[key])}; `;
      } else if (typeof dst_extras[key] === 'boolean') {
        string += `var ${key} = ${dst_extras[key]}; `;
      }
    });//todo потенциально учитывать pageIndex

    string += ' return {';
    mapka.forEach((item) => {
      if (item.condition && (typeof item.condition === 'boolean' || item.condition.indexOf('.') < 0)) {
        string += `${item.field_name}: (function() { try { return !!(${item.condition}) } catch(e) { return false } })(),`;
      } else {
        string += `${item.field_name}: ${item.condition ? `(function() { try { return !!(${item.condition}) } catch(e) { return false } })()` : 'true'},`;
      }

      // string += `${item.field_name}: ${item.condition ? `${item.condition}` : 'true'},`;
    });
    string += ' };';

    // const object = {};
    // mapka.forEach((item) => {
    //   let condition = item.condition || 'true';
    //   if (condition === 'typeof RecipientType !== "undefined"') {
    //     condition = 'typeof RecipientType != "undefined"';
    //   }
    //
    //   object[item.field_name] = expressions.compile(condition)(this.state.values);
    // });

    // return object;
    //eslint-disable-next-line
    // console.log('string ', string)
    return new Function('', string)();
    // + 'fieldname:' + 'fieldname.condition' + '};';
  };

  merchantPay = () => {
    //todo kostilik s checkom

    // const someShit = transformToConfirmForm(this.state.group, this.state.values);
    //todo место для печати
    // const cmd = new CmdFormatter(someShit, 36);

    const { passToOneC, txAfterCheck, form, platId, valuesAfterPrechecque } = this.state;
    const { user } = this.props;

    const cmd = printFiscalCheque(this.state.groups, { ...this.state.values, ...txAfterCheck.dst.extra }, [], platId);
    // const cmd = printFiscalCheque(this.state.group, this.state.values);

    const driverUrl = localStorage.getItem('DRIVER_ADDRESS');

    const urlString = driverUrl ? `${driverUrl}/fr` : `${process.env.DRIVER_ADDRESS}/fr`;

    // const { passToOneC, txAfterCheck, form, platId } = this.state;

    let GoodId = txAfterCheck.dst.extra.insuranceNomenKey || form.extras.nomen;

    if (platId === 'unistream_mt') {
      const { user } = this.props;
      const { nomenKey } = user.info.user;
      GoodId = (nomenKey && form.extras[nomenKey] && form.extras[nomenKey][txAfterCheck.dst.extra.country.value]) || form.extras[txAfterCheck.dst.extra.country.value] || txAfterCheck.dst.extra.insuranceNomenKey || form.extras.nomen;
    }

    const coreObjectToOneC = {
      price: txAfterCheck.dst.extra.full_amount,
      GoodId,
      product: platId,
      txId: txAfterCheck.tx.id,
    };

    const additionalObjectToOneC = {};

    if (txAfterCheck.dst.extra.clientPhoneFor1C) {
      additionalObjectToOneC.abnNumber = txAfterCheck.dst.extra.clientPhoneFor1C;
    }

    if (passToOneC && Object.keys(passToOneC).length > 0) {
      Object.keys(passToOneC).forEach((item) => {
        const keyFromForm = passToOneC[item];
        const splittedKeys = keyFromForm.split('+');

        // if lastName+firstName+middleName => return concat of three fields: 'Ivanov Ivan Ivanovich';
        if (splittedKeys.length > 1) {
          const splittedData = splittedKeys.map(it => txAfterCheck.dst.extra[it]).filter(it => it && it.trim() && it !== '-');
          additionalObjectToOneC[item] = splittedData.join(' ');
        } else {
          const value = txAfterCheck.dst.extra[keyFromForm];
          additionalObjectToOneC[item] = (value && value !== '-') ? value : '';
        }
      });
    }

    const foundAddress = Object.keys(txAfterCheck.dst.extra).find(key => key.includes('_json'));


    if (foundAddress) {
      const actualKey = txAfterCheck.dst.extra[foundAddress];
      console.log('actualKey ', actualKey);

      let kladrId = '';
      const addrFor1C = [];
      if (actualKey) {
        if (actualKey.region) {
          if (actualKey.region.id) {
            kladrId = actualKey.region.id;
            addrFor1C.push(`${actualKey.region.socr}. ${actualKey.region.name}`);
          }
        }

        if (actualKey.district) {
          if (actualKey.district.id) {
            kladrId = actualKey.district.id;
            addrFor1C.push(`${actualKey.district.socr}. ${actualKey.district.name}`);
          }
        }
        if (actualKey.city) {
          if (actualKey.city.id) {
            kladrId = actualKey.city.id;
            addrFor1C.push(`${actualKey.city.socr}. ${actualKey.city.name}`);
          }
        }

        if (actualKey.street && actualKey.street.name && actualKey.street.name.replace(/-*/, '')) {
          if (actualKey.street.id) {
            kladrId = actualKey.street.id;
            addrFor1C.push(`${actualKey.street.socr}. ${actualKey.street.name}`);
          }
        }

        if (actualKey.house) {
          addrFor1C.push(`дом ${actualKey.house}`);
        }

        if (actualKey.flat) {
          addrFor1C.push(`кв. ${actualKey.flat}`);
        }
      }

      const release = Number(sessionStorage.getItem('Release'));

      if (release >= 208110) {
        additionalObjectToOneC.addressclient = kladrId !== '' ? `${kladrId}, ${addrFor1C.join(', ')}` : addrFor1C.join(', '); // конец адреса
      }
    }

    console.log('additionalObjectToOneC ', additionalObjectToOneC);

    //todo merge this with formParams
    const notifyPromise = cmd.callFiscal('shtrih-m', 'COM3', urlString, cmd.cmd).then(() => sendNotify(coreObjectToOneC, additionalObjectToOneC)).catch(() => Promise.reject({ reason: 'fiscal' }));

    const currencyFromTx = this.state.txAfterCheck.dst.extra.currency;

    logger({
      message: 'Запрос до пеймента',
      data: {
        currencyFromTx,
        txAfterCheck: this.state.txAfterCheck,
        submitData: {
          amount: this.state.txAfterCheck.dst.extra.amount,
          amountWithCommission: this.state.txAfterCheck.dst.extra.full_amount,
          inner: this.state.form.inner,
          srcCls: 'cash',
          currency: currencyFromTx ? +ISO_VALUE[typeof currencyFromTx !== 'object' ? currencyFromTx : currencyFromTx.value] : 643,
          partnerTx: this.state.txAfterCheck.tx.id,
          formData: { ...this.state.txAfterCheck.dst.extra },
          product: this.state.platId,
        },
      },
      level: 'info',
      tag: 'pre_payment',
    });
    if (!process.env.IGNORE_FISCAL_ERRORS) {
      return notifyPromise
        .then(checknumber => payment(
          {
            amount: this.state.txAfterCheck.dst.extra.amount,
            amountWithCommission: this.state.txAfterCheck.dst.extra.full_amount,
            inner: this.state.form.inner,
            srcCls: 'cash',
            currency: currencyFromTx ? +ISO_VALUE[typeof currencyFromTx !== 'object' ? currencyFromTx : currencyFromTx.value] : 643,
            partnerTx: this.state.txAfterCheck.tx.id,
            formData: { ...valuesAfterPrechecque, ...this.state.txAfterCheck.dst.extra, checknumber: typeof checknumber === 'object' && checknumber.result ? checknumber.result : checknumber },
            product: this.state.platId,
          },
          {
            withOverlay: true,
            overlayMessageShowTime: 6000,
          },
        ))
        .catch((e) => {
          console.error(e);
          if (e.reason && e.reason === 'fiscal') {
            return Promise.reject({ message: e.message, reason: 'fiscal' });
          }
          return Promise.reject({ message: e.message, reason: 'payment' });
        })
        .then(() => {
          this.turnOffBeforeUnload();
          downloadFiles({
            id: this.state.platId,
            txId: this.state.txAfterCheck.tx.id,
            formData: this.state.txAfterCheck.dst.extra,
            step: 'cheque',
          });

          this.setState({ currentStep: 4 }, () => {
            if (this.state.redirect && this.state.redirect.id) {
              sessionStorage.setItem('CallsignFederal', this.state.values.CallsignFederal);
              this.props.history.push(`/reload-catalog-form-fill/${this.state.redirect.id}`);
            }
          });
        })
        .catch((e) => {
          console.error(e);
          this.turnOffBeforeUnload();
          if (e.reason === 'fiscal') {
            this.setState({
              formError: true,
              formErrorMessage: 'Произошла ошибка в работе фискального регистратора.',
            });
          } else if (e.reason === 'payment') {
            this.setState({
              formError: true,
              formErrorMessage: `Произошла ошибка при проведении платежа. ${e.message ? `Описание ошибки "${e.message}".` : ''} Необходимо получить подтверждение на возврат денежных средств и чека в 1С в СП Teko (через онлайн чат Boxretail). При проведении возврата без подтверждения со стороны Teko сотрудник берет на себя ответственность за возможную финансовую потерю.`,
            });
          }
          return Promise.reject();
        });
    }
    return payment(
      {
        amount: this.state.txAfterCheck.dst.extra.amount,
        amountWithCommission: this.state.txAfterCheck.dst.extra.full_amount,
        inner: this.state.form.inner,
        srcCls: 'cash',
        currency: currencyFromTx ? +ISO_VALUE[typeof currencyFromTx !== 'object' ? currencyFromTx : currencyFromTx.value] : 643,
        partnerTx: this.state.txAfterCheck.tx.id,
        formData: { ...valuesAfterPrechecque, ...this.state.txAfterCheck.dst.extra },
        product: this.state.platId,
      },
      {
        withOverlay: true,
        overlayMessageShowTime: 6000,
      },
    )
      .then((result) => {
      //todo result may need to be displayed later
        // this.props.updateCurrentStep(5);
        this.turnOffBeforeUnload();
        downloadFiles({ //
          id: this.state.platId,
          txId: this.state.txAfterCheck.tx.id,
          formData: this.state.txAfterCheck.dst.extra,
          step: 'cheque',
        });
        this.setState({ currentStep: 4, startedTxId: '' }, () => {
          if (this.state.redirect && this.state.redirect.id) {
            sessionStorage.setItem('CallsignFederal', this.state.values.CallsignFederal);
            this.props.history.push(`/reload-catalog-form-fill/${this.state.redirect.id}`);
          }
        });
      }).catch((e) => {
        console.error(e);
        this.turnOffBeforeUnload();
        this.setState({
          formError: true,
          formErrorMessage: `Произошла ошибка при проведении платежа. ${e.message ? `Описание ошибки "${e.message}".` : ''} Необходимо получить подтверждение на возврат денежных средств и чека в 1С в СП Teko (через онлайн чат Boxretail). При проведении возврата без подтверждения со стороны Teko сотрудник берет на себя ответственность за возможную финансовую потерю.`,
          startedTxId: '',
        });
      });
  };

  onSubmit = (values) => {
    const developmentDevicePrice = window.sessionStorage.getItem('price') || '10000';
    const devicePrice = process.env.PRODUCTION ? window.sessionStorage.getItem('price') : developmentDevicePrice;
    // const currentFormState = form.getState();
    // if (currentFormState.submitFailed === true) {
    //   return;
    // }
    if (this.state.formSubmitted === false) {
      this.setState({ formSubmitted: true });
    } else {
      return;
    }

    const { form: templateData, calcData, platId, groups } = this.state;
    const { user } = this.props;

    if (platId === 'drugie_pravila') {
      let fieldSet = [];
      const data = values;

      groups.forEach((gr) => {
        gr.fields.forEach((fld) => {
          let value = '';

          if (['id_series', 'id_number'].indexOf(fld.field_name) !== -1) {
            return;
          }
          if (fld.field_name === 'contactownerType') {
            fieldSet.push({
              name: 'contact.ownerType',
              value: [data[fld.field_name].value],
            });
            return;
          }
          if (['creditAmount', 'income_total'].indexOf(fld.field_name) !== -1) {
            fieldSet.push({
              name: fld.field_name,
              value: +data[fld.field_name],
            });
            return;
          }

          // creditAmount

          if (typeof data[fld.field_name] !== 'undefined' && !!data[fld.field_name]) {
            if (fld.type === 'enum') {
              value = [data[fld.field_name].value];
            } else if (fld.type === 'date') {
              value = moment(data[fld.field_name]).format('DD.MM.YYYY');
            } else if (fld.field_name === 'passport_docIssuerCode') {
              value = data[fld.field_name].split('-').join('');
            } else if (fld.type === 'address') {
              value = {
                countryCode: '643',
              };

              const jsonFieldName = `${fld.field_name}_json`;

              if (data[jsonFieldName]) {
                const objectWithData = data[jsonFieldName];
                const wtf = Object.keys(objectWithData).map((key) => {
                  if (!!objectWithData[key] && typeof objectWithData[key] === 'object') {
                    if (typeof objectWithData[key].id === 'string') {
                      return +objectWithData[key].id;
                    }
                    return 0;
                  }
                  return 0;
                });

                const kladr = `${Math.max.apply(null, wtf)}`;
                if (objectWithData.street && objectWithData.street.name !== '----') {
                  value.street = objectWithData.street.name;
                  value.streetType = objectWithData.street.socr;
                }
                if (objectWithData.district && objectWithData.district.name !== '----') {
                  value.district = objectWithData.district.name;
                  value.districtType = objectWithData.district.socr;
                }
                if (objectWithData.city && objectWithData.city.name !== '----') {
                  value.city = objectWithData.city.name;
                  value.cityType = objectWithData.city.socr;
                }

                if (objectWithData.region && objectWithData.region.name !== '----') {
                  value.region = objectWithData.region.name;
                  value.regionType = objectWithData.region.socr;
                  value.regionCode = `${kladr[0]}${kladr[1]}`;
                }

                if (objectWithData.building && objectWithData.building !== '----') {
                  value.houseStroen = objectWithData.building;
                }
                if (objectWithData.house && objectWithData.house !== '----') {
                  value.houseNum = objectWithData.house;
                }

                if (objectWithData.flat && objectWithData.flat !== '----') {
                  value.flatNum = objectWithData.flat;
                }

                value.kladr = kladr;
              }
              ///todo uuushka
              // value = data[fld.field_name];
            } else {
              value = data[fld.field_name];
            }
            if (['primaryIncome_employer_address', 'home_address', 'registration_address'].indexOf(fld.field_name) === -1
              || (['primaryIncome_employer_address', 'home_address', 'registration_address'].indexOf(fld.field_name) !== -1 && typeof value !== 'string')) {
              fieldSet.push({
                name: fld.field_name,
                value,
              });
            }
          }
        });
      });


      if (!!data.id_series && !!data.id_number) {
        fieldSet.push({
          name: 'passport.docSeriesNumber',
          value: `${data.id_series}${data.id_number}`,
        });
      }

      fieldSet = fieldSet.map(item => ({ ...item, name: item.name.replace(/\_/g, '.') }));
      const bodyToSend = {
        value: {
          formTargetIds: ['tcs.credit_card'],
          fieldSet,
        },
      };

      let responseWrap;
      let formId;

      const newFormData = {
        amount: 0,
        amountWithCommission: 0,
        full_amount: 0,
        comission: 0,
        eyelineFormData: 'test',
        POSID: {},
      };

      bodyToSend.value.fieldSet.forEach((item) => {
        // if(typeof item.value !== 'object') {
        newFormData[item.name.replace('.', '_').replace('.', '_')] = typeof item.value === 'object' && typeof item.value[0] !== 'undefined' ? item.value[0] : item.value;
        // }
      });
      const { user } = this.props;
      newFormData.pos = {
        posId: (user.info.user && user.info.user.outletExtra && user.info.user.outletExtra['1c_point_code']) || '',
        userId: (user.info.user && user.info.user.fullName) || '',
        dealerId: (user.info.user && user.info.user.outletExtra && user.info.user.outletExtra.DLRID) || '',
      };

      const submitDataKostil = {
        product: 'drugie_pravila',
        inner: '${100}',
        srcCls: 'cash',
        amount: 0,
        amountWithCommission: 0,
        formData: newFormData,
        currency: 643,
        // initiator_id: controller.initiator_id,
        // blocked: controller.blocked //TODO for storage
      };
      // sendT2marketplaceForm(this.t2marketplaceAuthToken, bodyToSend.value);
      merchantCheck(submitDataKostil).then((response) => {
        responseWrap = { ...submitDataKostil, formData: { ...submitDataKostil.formData, ...response.result.dst.extra }, partnerTx: response.result.tx.id };
        return sendT2marketplaceForm(this.t2marketplaceAuthToken, bodyToSend.value);
      })
        .then((result) => {
          formId = result.result.formId;
          //todo pay -> redirect to ok!
          // console.log(formId)
          return sendNotify({
            price: 0,
            GoodId: responseWrap.dst && responseWrap.dst.extra && responseWrap.dst.extra.insuranceNomenKey ? responseWrap.dst.extra.insuranceNomenKey : '0100-415673',
            Type: formId,
            txId: responseWrap.partnerTx,
          }, {
            NomerCheckaKKM: Math.floor(Math.random() * 100000),
            Comment: responseWrap.partnerTx,
            tekoguid: `${responseWrap.product}_${responseWrap.partnerTx}`,
            ...((responseWrap.dst && responseWrap.dst.extra && responseWrap.dst.extra.clientPhoneFor1C) ? { abnNumber: responseWrap.dst.extra.clientPhoneFor1C } : null),
          });
        })
        .then(() => {
          // console.log('CMON ', responseWrap);
          responseWrap.formData.formId = formId;
          return payment(responseWrap,
            {
              withOverlay: true,
              overlayMessageShowTime: 6000,
            });
        })
        .then(() => {
          this.setState({ currentStep: 4 }, () => {
            if (this.state.redirect && this.state.redirect.id) {
              sessionStorage.setItem('CallsignFederal', this.state.values.CallsignFederal);
              this.props.history.push(`/reload-catalog-form-fill/${this.state.redirect.id}`);
            }
          });
        })
        .catch(() => {
          this.turnOffBeforeUnload();
          this.setState({
            formError: true,
            formSubmitted: false,
            formErrorMessage: 'Ошибка при оформлении заявки. Уточните корректность введенных данных и повторите попытку. В случае повторения ошибки - обратитесь в службу поддержки ТЕКО - support@teko.io',
          });
        });

      return;
    }
    const complexSalesGuid = window.sessionStorage.getItem('complexSalesGuid');
    let KOSTIL = {};
    if (platId === 'vtb_test' || platId === 'vtb_test_warranty') {
      KOSTIL = {
        IssueDate: Date.now(),
        StartDate: moment().add(8, 'days').hour(0).minute(0)
          .second(0)
          .valueOf(),
        EndDate: moment().add(1, 'year').add(7, 'days').hour(0)
          .minute(0)
          .second(0)
          .valueOf(),
      };
    }

    if (platId === 'vtb_hello_neighbor') {
      KOSTIL = {
        IssueDate: Date.now(),
        StartDate: moment().add(11, 'days').hour(0).minute(0)
          .second(0)
          .valueOf(),
        EndDate: moment().add(1, 'year').add(10, 'days').hour(0)
          .minute(0)
          .second(0)
          .valueOf(),
      };
    }

    const amount = this.state.dst_amount === -1 ? this.state.full_amount : this.state.dst_amount;

    const valuesWithRawKeys = Object.keys(values).reduce((accum, currentKey) => {
      const key = currentKey[0] === '_' ? currentKey.replace(/_/, '') : currentKey;
      return { ...accum, [key]: values[currentKey] };
    }, {});

    // console.log('this.state.loyaltyCode ', this.state.loyaltyCode);

    let loyaltyCode;
    if (platId !== 'smart_protect_check') {
      loyaltyCode = this.state.loyaltyCode && this.state.loyaltyCode !== '' ? this.state.loyaltyCode.replace(/\D/g, '') : undefined;
    } else {
      loyaltyCode = valuesWithRawKeys.certId;
    }
    const submitData = {
      product: platId,
      inner: templateData.inner,
      srcCls: 'cash',
      tekoCustomerId: loyaltyCode,
      ...(platId === 'complex_plus_barcode_discount' ? { amount: 0 } : { amount }),
      ...(platId === 'complex_plus_barcode_discount' ? { amountWithCommission: 0 } : { amountWithCommission: this.state.full_amount }),
      formData: {
        ...valuesWithRawKeys,
        ...calcData,
        ...KOSTIL,
        ...(platId === 'complex_plus_barcode_discount' ? { amount: 0 } : { amount }),
        ...(platId === 'complex_plus_barcode_discount' ? { full_amount: 0 } : { full_amount: calcData.full_amount }),
        complexSalesGuid: complexSalesGuid || '0',
        price: devicePrice,
        statementSum: values.statementSum * 100,
      },
      currency: values.currency ? +ISO_VALUE[typeof values.currency !== 'object' ? values.currency : values.currency.value] : 643,
    };

    merchantCheck(submitData, { log: true }).then((result) => {
      this.state.change('full_amount', result.result.src_payment.amount);

      this.setState({
        trnRate: result.result.rate,
        comission: (result.result.dst.extra.amount == -1 || result.result.dst.extra.dst_amount == -1) ? 0 : result.result.src_payment.amount - Math.round(result.result.dst.extra.dst_amount * result.result.dst.extra.rate), // result.dst.extra.rate
        vskDiscount: result.result.dst.extra.vskDiscount,
        realDiscount: result.result.dst.extra.realDiscount,
        txId: result.result.tx.id,
        dstExtra: result.result.dst.extra,
      });

      // comission: (result.dst_payment.amount === -1 || values.dst_amount === -1) ? 0 : result.src_payment.amount - Math.round(result.dst_payment.amount * rate),
      //   dst_amount_rur: Math.round(result.dst_payment.amount * rate * 100) / 100,
      //
      //   trnRate: result.rate || 1,

      const someShit = transformToConfirmForm(groups, this.state.values);
      //todo место для печати
      const cmd = new CmdFormatter(someShit);
      const isBpaContact = isBpaContactPlat(platId);
      const isBpaProduct = isBpaSubstrings.some((substring) => platId.includes(substring));

      if (isBpaContact) {
        cmd.center(user.info.company.address);
      }
      cmd.command('run', [2]);


      cmd.fill('#');
      cmd.center('Данный документ не имеет юридической силы');
      cmd.center('и не подтверждает факт оплаты.');
      cmd.fill('#');
      if (result.result.dst.extra.precheckText) {
        cmd.center(result.result.dst.extra.precheckText);
      }

      if (platId === 'unistream_mt' || platId === 'tinkoff' || platId === 'unistream_cards' || platId === 'unistream_loan') {
        cmd.string('Наименование субагента, его ИНН', '');
        cmd.string('Адрес и телефон субагента', '');
      }

      for (const property in cmd.p) {
        if (isBpaProduct && ['id_series_domestic', 'id_number_domestic', 'id_series_foreign', 'id_number_foreign'].includes(property)) {
          continue;
        }
        const value = cmd.p[property];
        cmd.string(value[0], value[1]);
      }

      if (isBpaContact) {
        cmd.center(`Кассир: ${user.info.user.fullName}`);
      }
      if (isBpaProduct) {
        cmd.string('Подпись клиента', '.');
        cmd.fill('#');
      }
      cmd.center('Адрес места приема денежных ср-в');
      cmd.center(this.props.user.info.user.outletAddress);

      if (platId === 'unistream_mt' || platId === 'tinkoff' || platId === 'unistream_cards' || platId === 'unistream_loan') {
        cmd.fill('#');
        cmd.center('С Условиями осуществления денежных');
        cmd.center('переводов');
        cmd.center('по Международной платежной системе');
        cmd.center('денежных переводов');
        cmd.center('«ЮНИСТРИМ» согласен.');
        cmd.string('Подпись клиента', '.');
        cmd.fill('#');
        cmd.center('Телефон службы поддержки клиентов');
        cmd.center('АО КБ "ЮНИСТРИМ":');
        cmd.center('8 495 744 55 55 (круглосуточно)');
      }

      const driverUrl = window.localStorage.getItem('DRIVER_ADDRESS');

      const urlString = driverUrl ? `${driverUrl}/fr` : `${process.env.DRIVER_ADDRESS}/fr`;

      cmd.callFiscal('shtrih-m', 'COM3', urlString, cmd.cmd)
        .then(null, () => {
          notification.open({
            duration: 5,
            description: 'Фискальный накопитель не отвечает.',
          });
        });
      if (complexSalesGuid !== '0' && complexSalesGuid !== null) {
        logger({ message: 'Возвращаем пользователя в 1С', data: submitData, level: 'info', tag: '1C_redirect' });
        //todo notify + modal

        const { passToOneC } = this.state;

        const coreObjectToOneC = {
          price: result.result.dst.extra.full_amount,
          GoodId: result.result.dst.extra.insuranceNomenKey || this.state.form.extras.nomen,
          product: this.state.platId,
          txId: result.result.tx.id,
          complexSalesGuid,
        };

        const additionalObjectToOneC = {};

        if (result.result.dst.extra.clientPhoneFor1C) {
          additionalObjectToOneC.abnNumber = result.result.dst.extra.clientPhoneFor1C;
        }

        if (passToOneC && Object.keys(passToOneC).length > 0) {
          Object.keys(passToOneC).forEach((item) => {
            const keyFromForm = passToOneC[item];
            const splittedKeys = keyFromForm.split('+');

            // if lastName+firstName+middleName => return concat of three fields: 'Ivanov Ivan Ivanovich';
            if (splittedKeys.length > 1) {
              const splittedData = splittedKeys.map(it => result.result.dst.extra[it]).filter(it => it && it.trim() && it !== '-');
              additionalObjectToOneC[item] = splittedData.join(' ');
            } else {
              const value = result.result.dst.extra[keyFromForm];
              additionalObjectToOneC[item] = (value && value !== '-') ? value : '';
            }
          });
        }

        const foundAddress = Object.keys(result.result.dst.extra).find(key => key.includes('_json'));
        if (foundAddress) {
          const actualKey = result.result.dst.extra[foundAddress];
          console.log('actualKey ', actualKey);

          let kladrId = '';
          const addrFor1C = [];
          if (actualKey) {
            if (actualKey.region) {
              if (actualKey.region.id) {
                kladrId = actualKey.region.id;
                addrFor1C.push(`${actualKey.region.socr}. ${actualKey.region.name}`);
              }
            }

            if (actualKey.district) {
              if (actualKey.district.id) {
                kladrId = actualKey.district.id;
                addrFor1C.push(`${actualKey.district.socr}. ${actualKey.district.name}`);
              }
            }
            if (actualKey.city) {
              if (actualKey.city.id) {
                kladrId = actualKey.city.id;
                addrFor1C.push(`${actualKey.city.socr}. ${actualKey.city.name}`);
              }
            }

            if (actualKey.street && actualKey.street.name && actualKey.street.name.replace(/-*/, '')) {
              if (actualKey.street.id) {
                kladrId = actualKey.street.id;
                addrFor1C.push(`${actualKey.street.socr}. ${actualKey.street.name}`);
              }
            }

            if (actualKey.house) {
              addrFor1C.push(`дом ${actualKey.house}`);
            }

            if (actualKey.flat) {
              addrFor1C.push(`кв. ${actualKey.flat}`);
            }
          }

          const release = Number(window.sessionStorage.getItem('Release'));

          if (release >= 208110) {
            additionalObjectToOneC.addressclient = kladrId !== '' ? `${kladrId}, ${addrFor1C.join(', ')}` : addrFor1C.join(', '); // конец адреса
          }
        }


        //todo KOSTILINA
        if (platId === 'bundle') {
          Promise.all([
            sendNotify({
              price: 0,
              GoodId: result.result.dst.extra.goods,
              Type: platId,
              txId: coreObjectToOneC.txId,
              complexSalesGuid,
            }, {
              ...additionalObjectToOneC,
              Discount: result.result.dst.extra.discount,
              tekoguid: '0',
            }, platId),
            sendNotify(coreObjectToOneC, additionalObjectToOneC),
          ])
            .then(() => {
              this.turnOffBeforeUnload();
              // if (multipleSaleAvailable) {
              this.setState({ currentStep: 4 }, () => {
                if (this.state.redirect && this.state.redirect.id) {
                  sessionStorage.setItem('CallsignFederal', this.state.values.CallsignFederal);
                  this.props.history.push(`/reload-catalog-form-fill/${this.state.redirect.id}`);
                }
              });
            // } else {
            //   return window.close();
            // }
            }, () => {
              this.turnOffBeforeUnload();
              return windowCloseWithNotify();
            // return window.close();
            });
        } else if (platId === 'smart_protect_confirm') {
          sendNotify({
            ...coreObjectToOneC,
            price: 0,
            GoodId: sessionStorage.getItem('goods'),
            Type: platId,
            txId: coreObjectToOneC.txId,
            complexSalesGuid,
          }, {
            ...additionalObjectToOneC,
            Discount: result.result.dst.extra.discount,
          }).then(() => {
            this.turnOffBeforeUnload();
            // if (multipleSaleAvailable) {
            this.setState({ currentStep: 4 }, () => {
              if (this.state.redirect && this.state.redirect.id) {
                sessionStorage.setItem('CallsignFederal', this.state.values.CallsignFederal);
                this.props.history.push(`/reload-catalog-form-fill/${this.state.redirect.id}`);
              }
            });
            // } else {
            //   return window.close();
            // }
          }, () => {
            this.turnOffBeforeUnload();
            return windowCloseWithNotify();
            // return window.close();
          });
        } else if (platId === 'complex_plus_barcode_discount') {
          this.setState({ currentStep: 2 });
        } else {
          if (!process.env.PRODUCTION) {
            // this.setState({ currentStep: 4 }, () => {
            //   if (this.state.redirect && this.state.redirect.id) {
            //     sessionStorage.setItem('CallsignFederal', this.state.values.CallsignFederal)
            //     this.props.history.push(`/reload-catalog-form-fill/${this.state.redirect.id}`);
            //   };
            // }); //TODO KOSTIL DLY TESTA REMOVE ON PROD!!!
          }
          // todo add this to redux if we are from cart + set button to redirect to new plat

          const modifiedSendNotify = () => (process.env.NODE_ENV === 'development' ? Promise.resolve() : sendNotify(coreObjectToOneC, additionalObjectToOneC, platId))
            .then(() => {
              if (platId === 'vsk_dms') {
                return new Promise((resolve, reject) => {
                  this.setState({ vskDmsSuggestModalActive: true });
                  this.onConfirmVskDmsSuggest = () => {
                    // for second notify if necessary
                    // sessionStorage.setItem('prevProductSendNotifyData', JSON.stringify({ coreObjectToOneC, additionalObjectToOneC, platId }));
                    this.props.history.push('/reload-catalog-form-fill/migrants_support');
                    reject();
                    this.setState({ vskDmsSuggestModalActive: false });
                  };

                  this.onDeclineVskDmsSuggest = () => {
                    resolve();
                    this.setState({ vskDmsSuggestModalActive: false });
                  };
                });
              }
              return Promise.resolve();
            }, () => {
              this.turnOffBeforeUnload();
              return windowCloseWithNotify();
              // return window.close();
            }).catch(console.log);

          this.props.getCartFromTekoAsync()
            .then((result) => {
              console.log('result of cart ', result);

              const newCartItems = result.items;
              // Antony said nobody never visits cart => sessionStorage cartWasInited will be always null
              if (this.props.tekoCart && this.props.tekoCart.cartList.length > 0 && sessionStorage.getItem('cartWasInited')) {
                // if (this.props.tekoCart.cartList.findIndex(item => item.status === 'added') !== -1) {
                this.props.updateCartData({
                  coreObjectToOneC,
                  additionalObjectToOneC,
                  nomen: sessionStorage.getItem('ImeiSerial'),
                  cat: this.state.currentCat,
                  product_id: this.state.platId,
                })
                  .then(() => {
                    const newAddedItemIndex = newCartItems.findIndex(item => item.tx === '');
                    if (newAddedItemIndex !== -1) {
                      this.props.setActiveCartItem(newAddedItemIndex, true);
                      // const newFormData = this.props.tekoCart.cartList[newAddedItemIndex];
                      const plat = newCartItems[newAddedItemIndex].id; // todo взять платежку c бека + sessionStorage
                      const { deviceList } = this.props;
                      const currentItem = deviceList.find(device => device.id == newCartItems[newAddedItemIndex].good_id);

                      const relatedGoodIndex = result.goods.findIndex(good => good.id === newCartItems[newAddedItemIndex].good_id);

                      const good = result.goods[relatedGoodIndex];

                      window.sessionStorage.setItem('price', currentItem.price / 100);
                      window.sessionStorage.setItem('imei', newCartItems[newAddedItemIndex].good_id);
                      window.sessionStorage.setItem('goods', good.group_id);
                      this.props.history.push(`/reload-catalog-form-fill/${plat}`);
                    } else {
                      // this.props.setActiveCartItem(newAddedItemIndex, false);
                      this.props.history.push('/cart');
                    }
                  });
                // } else {
                //   this.props.history.push('/cart');
                // }
              } else {
                modifiedSendNotify();
              }
            })
            .catch(modifiedSendNotify)
            .then(() => {
              this.turnOffBeforeUnload();
              this.setState({ currentStep: 4 }, () => {
                if (this.state.redirect && this.state.redirect.id) {
                  sessionStorage.setItem('CallsignFederal', this.state.values.CallsignFederal);
                  this.props.history.push(`/reload-catalog-form-fill/${this.state.redirect.id}`);
                }
              });
            });
        }
      } else {
        window.onbeforeunload = undefined;
        downloadFiles({
          id: platId,
          txId: result.result.tx.id,
          formData: result.result.dst.extra,
        }).then(() => {
          setTimeout(() => {
            window.onbeforeunload = onbeforeunload;
          }, 10);
        });
        this.setState({ currentStep: 2, formSubmitted: false, txAfterCheck: result.result, full_amount: result.result.dst.extra.full_amount, startedTxId: result.result.tx.id }, () => {
          this.props.removeTransaction();
        });//todo save somewhere
      }
    }).catch((e) => {
      console.error(e);
      this.turnOffBeforeUnload();
      this.setState({
        formError: true,
        formSubmitted: false,
        formErrorMessage: `Ошибка проверки параметров платежа. Описание ошибки "${e.message === 'Number belongs to some other subscriber' ? 'Данная сим-карта уже принадлежит другому абоненту, необходимо выбрать другую сим-карту' : e.message}". Уточните корректность введенных данных и повторите попытку. В случае повторения ошибки - обратитесь в службу поддержки ТЕКО - онлайн чат в правом нижнем углу экрана`,
      });
    });//todo place for error
  };

  complexSalesOnBarCodeSubmit = () => {
    const { location } = this.props;
    const { values } = this.state;
    if (location.pathname.split('/')[2] === 'complex_plus_barcode_discount') {
      this.setState({ complexPlusBarcodeLoading: true });
      fetch('/getInfoByBarcode', {
        method: 'POST',
        body: {
          applicationId: values.numberComplexPlus,
        },
      })
        .then(res => this.setState({
          currentStep: 2,
          formSubmitted: false,
          values: {
            ...values,
            ...res.result,
            applicantLastName: res.result.applicantLastName,
            applicantFirstName: res.result.applicantFirstName,
            applicantMiddleName: res.result.applicantMiddleName,
            applicantBirthDate: res.result.applicantBirthDate,
            applicantDocumentType: (res.result.applicantDocumentType.value === 'DOC_TYPE_1' && 'Паспорт РФ'),
            applicantDocumentNumber: res.result.applicantDocumentNumber,
            statementSum: res.result.statementSum / 100,
          },
        }))
        .then(() => {
          this.setState({ complexPlusBarcodeLoading: false });
          this.onSubmit(this.state.values);
        });
    }
  }

  complexSalesOnBarCodeToOneC = (platId) => {
    const { values, realDiscount, txId } = this.state;
    sendNotify({
      price: 0,
      costDevice: window.sessionStorage.getItem('price'),
      GoodId: window.sessionStorage.getItem('goods'),
      complexSalesGuid: window.sessionStorage.getItem('complexSalesGuid'),
      txId: txId || window.sessionStorage.getItem('complexSalesGuid'),
    }, {
      Discount: realDiscount / 100,
      NumberComplexPlus: values.policyNumber,
      ...(values.clientPhoneFor1C ? { abnNumber: values.clientPhoneFor1C } : null),
    }, platId)
      .then(() => {
        this.turnOffBeforeUnload();
        this.setState({ currentStep: 4 }, () => {
          if (this.state.redirect && this.state.redirect.id) {
            window.sessionStorage.setItem('CallsignFederal', this.state.values.CallsignFederal);
            this.props.history.push(`/reload-catalog-form-fill/${this.state.redirect.id}`);
          }
        });
      }, () => {
        this.turnOffBeforeUnload();
        return windowCloseWithNotify();
      });
  }

  onCloseWindowClick = () => {
    window.onbeforeunload = undefined;
    windowCloseWithNotify();
    // window.close();
  };

  onErrorBackToFormClick = () => {
    this.setState(prevState => ({ currentStep: prevState.currentStep !== 1 ? --prevState.currentStep : 1, formSubmitted: false, formError: false, formErrorMessage: '' }));
  };

  onApplyTemplate = ({ loyaltyCode, dst }) => {
    this.setState({ loyaltyCode, disabledFields: this.initialDisabledFields });

    // const keys = this.state.groups.flatMap(g => g.fields).map(field => field.field_name);
    //
    // const filteredDst = Object.keys(dst).filter(key => keys.includes(key) || key.includes('_json')).reduce((obj, key) => {
    //   obj[key] = dst[key];
    //   return obj;
    // }, {});

    const {
      trnReference,
      certificate,
      signature,
      registerPolicy,
      jsonString,
      sha1hash,
      dst_amount,
      src_amount,
      trnRate,
      dst_amount_rur,
      comission,
      full_amount,
      rate,
      ...filteredDst
    } = dst;

    filteredDst.amount = dst_amount;

    this.state.reset(filteredDst);

    const { values } = this.state;
    let amountForCalc = -1;
    let typeOfCalc = 'out';
    //todo Место для определения типа in out
    if (values.amount) {
      amountForCalc = values.amount;
      typeOfCalc = 'out';
    }
    this.debouncingComission(amountForCalc, typeOfCalc, values.currency, values);
  };


  forceCalc = () => {
    this.cancelDebouncingCalcFlag = true;
    return restPromise.then(() => new Promise((resolve, reject) => {
      const { values } = this.state;
      let amountForCalc = -1;
      let typeOfCalc = 'out';
      //todo Место для определения типа in out
      if (values.amount) {
        amountForCalc = values.amount;
        typeOfCalc = 'out';
      }
      this.calcCommission(amountForCalc, typeOfCalc, values.currency, values)
        .then((result) => {
          updateByFetch = true;
          this.state.change('full_amount', result.full_amount);
          this.state.change('amount', result.dst_amount);
          this.state.change('comission', result.comission);
          this.setState({
            ...result,
            calcData: result,
            calcSuccess: true,
          }, () => {
            updateByFetch = false;
            resolve(this.state.values);
          });
        }).catch((e) => {
          this.resetCommission();
          reject(e);
        });
    }));
  };

  onResetTemplate = () => {
    this.debouncingComission.cancel();
    if (this.cancelableComission) {
      this.cancelableComission.cancel();
    }
    this.setState({ disabledFields: this.initialDisabledFields });
    this.resetCommission();
    this.resetIndex++;
    this.state.reset({});
  };

  onUpdateSelectedTx = selectedTxTemplate => this.setState({ selectedTxTemplate });

  turnOffBeforeUnload = () => {
    window.onbeforeunload = undefined;
    this.props.updateOpenModalBeforeLeave(false);
  };

  resetCommission = () => this.setState({
    dst_amount: 0,
    amount: 0,
    full_amount: 0,
    comission: 0,
    trnRate: 0,
    calcData: {},
    calcSuccess: false,
  });

  setResetParameter = () => {
    this.setState(prevState => ({ resetParameter: prevState.resetParameter + 1 }));
  };

  getReduxFormDataWithCorrectFieldNames = () => {
    const { transaction, externalForm } = this.props;

    const values = transaction.dst_extras || externalForm.formData.formData;

    if (!values) return {};

    // const keys = this.state.groups.flatMap(g => g.fields).map(field => field.field_name);
    //
    // const filteredDst = Object.keys(values).filter(key => keys.includes(key) || key.includes('_json')).reduce((obj, key) => {
    //   obj[key] = values[key];
    //   return obj;
    // }, {});

    delete values.trnReference;
    delete values.certificate;
    delete values.signature;
    delete values.registerPolicy;
    delete values.jsonString;
    delete values.sha1hash;

    delete values.dst_amount;
    delete values.src_amount;
    delete values.trnRate;
    delete values.dst_amount_rur;
    delete values.comission;
    delete values.full_amount;
    delete values.rate;

    return Object.keys(values).reduce((accum, currentKey) => {
      const isFieldNameNumber = !Number.isNaN(parseInt(currentKey, 10)) && typeof parseInt(currentKey, 10) === 'number';
      const key = isFieldNameNumber ? `_${currentKey}` : currentKey;
      return { ...accum, [key]: values[currentKey] };
    }, {});
  };

  setFileOnAfterCheck = (fileName) => {
    const { valuesAfterPrechecque, additionalFormOnPrechecque } = this.state;
    this.setState({
      valuesAfterPrechecque: { ...valuesAfterPrechecque, [additionalFormOnPrechecque[0].field_name]: fileName },
    });
  }

  render() {
    const {
      currentStep, formError, formErrorMessage, form, currentCat, values = {}, disabledFields, change, formDefaultValues,
      selectedTxTemplate, multipleSaleAvailable, withTemplates, platId, groups, getState = () => {}, calcSuccess, resetFieldState,
      pageIndex, visible, showAmountFieldNames, ignoreLastEasySalesSteps, finishStepConfig, saleTypeMissmatch, resetParameter,
      additionalFormOnPrechecque, loyaltyInputName, complexPlusBarcodeLoading, isAsyncValidationInProgress, vskDmsSuggestModalActive,
    } = this.state;

    const { ui, transaction, history, user, location } = this.props;
    const complexSalesGuid = window.sessionStorage.getItem('complexSalesGuid');
    const leftBlockActiveStep = currentStep >= 2 ? (currentStep + pageIndex - 1) : pageIndex;

    if (!groups.length) return null;

    const reduxFormData = this.getReduxFormDataWithCorrectFieldNames();

    if (saleTypeMissmatch) {
      return (
        <PreventFormFill
          onBreadcrumbBackToCatClick={this.onBreadcrumbBackToCatClick}
          currentCat={currentCat || 'Назад'}
          form={form}
          onCloseWindowClick={this.onCloseWindowClick}
          onErrorBackToFormClick={this.onErrorBackToFormClick}
        />
      );
    }

    return (
      <div className="catalog-form-fill-page form-page form-page-fill">
        <div className="container" ref={ref => this.pageContainer = ref}>
          <div className="left-fixed-block" ref={ref => this.leftBlock.element = ref}>
            <div className="breadcrumbs-block">
              <span className="breadcrumb-link" onClick={this.onBreadcrumbBackToCatClick}>
                <ArrowDown className="breadcrumb-link-icon" />
                <span className="breadcrumb-link-text">{currentCat || 'Назад'}</span>
              </span>
              <span className="breadcrumb-link active">
                <span className="breadcrumb-link-text" dangerouslySetInnerHTML={{ __html: form.name || '' }} />
              </span>
            </div>
            <LeftFillSteps
              ignoreLastEasySalesSteps={ignoreLastEasySalesSteps}
              step={leftBlockActiveStep || 0}
              complexSalesGuid={complexSalesGuid}
              formSteps={groups}
              formError={formError}
              parentState={null}
              values={values}
            />
          </div>
          <div className={classnames('form-fill-content wizard-form', { 'precheck-content': currentStep === 2 })} ref={ref => this.formFillContent = ref}>
            <div className="form-title">
              <div className="form-logo">
                <ProductLogo productConfig={form} />
              </div>
              <div className="form-desc">
                <div className="form-name" dangerouslySetInnerHTML={{ __html: form.name || '' }} />
                <div className="form-cat">{currentCat || 'Неизвестная категория'}</div>
              </div>
            </div>
            {currentStep < 2 && groups.length !== 0 && (
              <div className={classnames('form-fill-info', { 'hidden-by-error': formError })}>
                {currentStep === 1 && (
                  <React.Fragment>
                    <h3 key="step-1-title" className="page-title h3" dangerouslySetInnerHTML={{ __html: `${pageIndex + 1 || 1}. ${groups[pageIndex || 0].name}` }} />
                    {(pageIndex === 0 || typeof pageIndex === 'undefined') && (
                      <React.Fragment key="templates-and-loaylty-fragment">
                        {withTemplates && (
                          <TemplatesBlock
                            key="templates-block"
                            productId={platId}
                            fieldGroups={groups}
                            selectedTx={selectedTxTemplate}
                            onApplyTemplate={this.onApplyTemplate}
                            onResetTemplate={this.onResetTemplate}
                            onUpdateSelectedTx={this.onUpdateSelectedTx}
                          />
                        )}
                        {withTemplates && (
                        <div key="loyalty-code-wrap" className="loyalty-code">
                          <label className="input-label">{loyaltyInputName}</label>
                          <MaskInput
                            mask="+7(999)-999-99-99"
                            onChange={a => this.setState({ loyaltyCode: a })}
                            value={this.state.loyaltyCode}
                          />
                        </div>
                        )}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                <Wizard
                  calcSuccess={calcSuccess} // calcSuccess
                  dataFromRedux={!!transaction.dst_extras}
                  stepValid={this.state.stepValid}
                  initialValues={returnFirstNonEmptyObject(values, reduxFormData, formDefaultValues, {})}
                  up={this.tossFormPropertiesUp}
                  onSubmit={this.onSubmit}
                  forceCalc={this.forceCalc}
                  location={location}
                  complexSalesOnBarCodeSubmit={this.complexSalesOnBarCodeSubmit}
                  loading={complexPlusBarcodeLoading || isAsyncValidationInProgress}
                >
                  {groups.map((group) => {
                    // const fileInputs = group.fields.filter(field => field.type === 'file');
                    // const amountInputs = group.fields.filter(field => ['amount', 'comission', 'full_amount'].includes(field.field_name));
                    // const regularInputs = group.fields.filter(field => field.type !== 'file');
                    const fileInputs = [];
                    const amountInputs = [];
                    const regularInputs = [];

                    for (let i = 0; i < group.fields.length; i++) {
                      const field = group.fields[i];
                      if (field.type === 'file') {
                        fileInputs.push(field);
                      } else if (amountFieldNames.includes(field.field_name)) {
                        amountInputs.push(field);
                      } else {
                        regularInputs.push(field);
                      }
                    }

                    return (
                      <Wizard.Page key={group.name}>
                        {regularInputs.map((item, fieldIndx) => {
                          const fieldKey = `${item.field_name}-wrap-${fieldIndx}-${resetParameter}`;
                          const styleFromParent = {
                            marginLeft: `${item.visual.off}%`,
                            marginRight: `${item.visual.post}%`,
                            maxWidth: `${item.visual.size}%`,
                          };
                          const commonFieldProps = {
                            item,
                            hint: item.fieldHint,
                            styleFromParent,
                            visible: visible[item.field_name],
                            disabled: item.disabled || disabledFields[item.field_name],
                            validation: this.validation,
                            change,
                          };

                          if (item.type === 'cardnumber') {
                            return <CardnumberFinalFieldWrap key={fieldKey} {...commonFieldProps} />;
                          }
                          if (item.type === 'date') {
                            return <DateFinalFieldWrap key={fieldKey} {...commonFieldProps} />;
                          }
                          if (item.type === 'enum') {
                            return (
                              <AutocompleteFinalFieldWrap
                                key={`${fieldKey}-${this.resetIndex}`}
                                {...commonFieldProps}
                                values={values}
                              />
                            );
                          }
                          if (item.type === 'typeahead') {
                            return (
                              <AutocompleteFinalFieldWrap
                                key={`${fieldKey}-${this.resetIndex}`}
                                {...commonFieldProps}
                                values={values}
                              />
                            );
                          }
                          if (item.type === 'phone') {
                            return (
                              <PhoneFinalFieldWrap
                                key={fieldKey}
                                {...commonFieldProps}
                                formConfing={form}
                                onFormConfigUpdate={this.tossFormPropertiesUp}
                              />
                            );
                          }
                          if (item.type === 'amount') {
                            return (
                              <AmountFinalFieldWrap
                                key={fieldKey}
                                {...commonFieldProps}
                                value={values[item.field_name]}
                                currencyValue={typeof values.currency !== 'object' ? values.currency : values.currency.value}
                              />
                            );
                          }
                          if (item.type === 'radio') {
                            return (
                              <RadioFinalFieldWrap
                                key={fieldKey}
                                {...commonFieldProps}
                                values={values}
                                updateRestPromise={updateRestPromise}
                                resetFieldState={(data) => {
                                  resetFieldState(data);
                                }}
                              />
                            );
                          }
                          if (item.type === 'address') {
                            return (
                              <Condition key={fieldKey} visible={visible[item.field_name]}>
                                <Address
                                  item={item}
                                  values={values}
                                  changeValue={change}
                                  validation={this.validation}
                                />
                              </Condition>
                            );
                          }
                          if (item.type === 'checkbox') {
                            return (
                              <CheckboxFinalFieldWrap
                                key={item.field_name}
                                {...commonFieldProps}
                                values={values}
                                setResetParameter={this.setResetParameter}
                                updateDisabledFields={this.updateDisabledFields}
                              />
                            );
                          }
                          if (documentFieldNames.includes(item.field_name)) {
                            return (
                              <DocumentFinalFieldWrap
                                key={item.field_name}
                                {...commonFieldProps}
                                documentCountry={values.id_country ? values.id_country.value : undefined}
                                documentType={values.id_type ? values.id_type.value : undefined}
                              />
                            );
                          }

                          if (platId === 'pet' && item.field_name === 'promoCodeId' || item.field_name === 'retailPromoCodeId') {
                            return (
                              <PromocodeFinalField
                                key={item.field_name}
                                platId={platId}
                                disabled={item.disabled || disabledFields[item.field_name]}
                                value={values[item.field_name]}
                                {...commonFieldProps}
                                setIsAsyncValidationInProgress={this.setIsAsyncValidationInProgress}
                                updateRestPromise={updateRestPromise}
                              />
                            );
                          }

                          return (
                            <Condition key={fieldKey} visible={visible[item.field_name]}>
                              <Field
                                type="text"
                                name={item.field_name}
                                placeholder={item.placeholder}
                                validate={value => this.validation(value, item)}
                              >
                                {({ input, meta }) => (
                                  <div className="form-item" style={styleFromParent}>
                                    <label className="input-label">{item.caption}</label>
                                    <ConditionalTooltip hint={item.hint}>
                                      <ValidateInput
                                        value={input.value}
                                        type={item.type}
                                        mask={item.mask}
                                        valid={meta.touched ? meta.valid : true}
                                        message={item.comment}
                                        placeholder={item.placeholder}
                                        disabled={item.disabled || disabledFields[item.field_name]}
                                        {...input}
                                      />
                                    </ConditionalTooltip>
                                    <Error name={item.field_name} hint={item.fieldHint} />
                                  </div>
                                )}
                              </Field>
                            </Condition>
                          );
                        })}
                        {fileInputs.length > 0 && (
                          <div className="file-inputs-group">
                            <div className="file-inputs-title">Загрузка документов</div>
                            <div className="file-inputs-list">
                              {fileInputs.map((item, i) => (
                                <FileInputFinalFieldWrap
                                  key={`${item.field_name}-wrap-${i}`}
                                  item={{ ...item, caption: `${i + 1}. ${item.caption}` }}
                                  visible={visible[item.field_name]}
                                  disabled={item.disabled || disabledFields[item.field_name]}
                                  validation={this.validation}
                                  values={values}
                                  change={change}
                                />
                              ))}
                            </div>
                          </div>
                        )}
                        {showAmountFieldNames && amountInputs.length > 0 && amountInputs.map((item, i) => {
                          const styleFromParent = {
                            marginLeft: `${item.visual.off}%`,
                            marginRight: `${item.visual.post}%`,
                            maxWidth: `${item.visual.size}%`,
                          };

                          return (
                            <AmountFinalFieldWrap
                              key={`${item.field_name}-wrap-${i}`}
                              value={values[item.field_name]}
                              currencyValue={typeof values.currency !== 'object' ? values.currency : values.currency.value}
                              item={item}
                              styleFromParent={styleFromParent}
                              visible={visible[item.field_name]}
                              disabled={item.disabled || disabledFields[item.field_name] || kostilDisabledFieldNames.includes(item.field_name)}
                              validation={this.validation}
                              change={change}
                            />
                          );
                        })}
                      </Wizard.Page>
                    );
                  })}
                </Wizard>
              </div>
            )}

            {currentStep === 2 && (
              <div className={classnames('precheck-details-list', { 'hidden-by-error': formError })}>
                {platId !== 'complex_plus_barcode_discount' && (
                  <h3 className="page-title h3">
                    {`${groups.length + 1}. Предварительный чек`}
                  </h3>
                )}
                <div className="ui-alert ui-alert-blue">
                  <div className="ui-alert-content">
                    <span>
                      {
                        (platId === 'complex_plus_barcode_discount')
                          ? 'Обязательно сверьте данные с паспортными данными клиента'
                          : 'Обязательно проверьте корректность введенных данных'
                      }
                    </span>
                  </div>
                </div>
                <div className="list-inner">
                  {groups.map(subGroup => (
                    <React.Fragment key={subGroup.name}>
                      <h4 key={`step-4-${subGroup.name}-title`} className="fields-group-title" dangerouslySetInnerHTML={{ __html: subGroup.name }} />
                      {subGroup.fields.filter(field => typeof values[field.field_name] !== 'undefined' && field.hideFromPrecheque !== true).map(field => (
                        <TxDataField
                          key={subGroup.name + field.field_name}
                          field={field}
                          value={values[field.field_name]}
                          amountCurrency={values.currency}
                        />
                      ))}
                    </React.Fragment>
                  ))}
                  {additionalFormOnPrechecque.length !== 0 && (
                    <>
                      <h4 key="step-4-file-upload-title" className="fields-group-title" dangerouslySetInnerHTML={{ __html: additionalFormOnPrechecque[0].caption }} />
                      <div className="fields-group-item">
                        <FileInput
                          fileInputConfig={additionalFormOnPrechecque[0].fileInputConfig}
                          item={{
                            field_name: additionalFormOnPrechecque[0].field_name,
                          }}
                          values={{}}
                          value={undefined}
                          onChange={this.setFileOnAfterCheck}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className={classnames('form-footer-controls')}>
                  <Button onClick={() => this.setState({ currentStep: 1 })} label="Изменить данные" color={Button.Color.white} />
                  <Button
                    onClick={() => {
                      if (platId === 'complex_plus_barcode_discount') {
                        this.complexSalesOnBarCodeToOneC(platId);
                      } else {
                        this.setState({ currentStep: 3 });
                      }
                    }}
                    disabled={!(additionalFormOnPrechecque.length === 0 || (additionalFormOnPrechecque.length !== 0 && Object.keys(this.state.valuesAfterPrechecque)[0] && this.state.valuesAfterPrechecque[Object.keys(this.state.valuesAfterPrechecque)[0]]))}
                    label="Подтвердить"
                    color={Button.Color.blue}
                  />
                </div>
              </div>
            )}

            {currentStep === 3 && (
              <SubmitEasySale
                stepIndex={groups.length + 2}
                full_amount={this.state.values.full_amount}
                formError={formError}
                updateCurrentStep={step => this.setState({ currentStep: step })}
                merchantPay={this.merchantPay}
              />
            )}

            {currentStep === 4 && (
              <div className={classnames('payments-phase payments-phase-success status-screen', { 'hidden-by-error': formError, 'with-multiple-buttons': multipleSaleAvailable })}>
                <div className="picture-wrap">
                  <SuccessStatus className="picture-svg" />
                </div>
                <div className="finish-step-title">Операция успешно завершена</div>

                {this.state.combo_sets && this.state.combo_sets.length > 0 && this.state.combo_sets.sort((a, b) => a.amount - b.amount).map((combo) => {
                  const { combo_name: comboName, amount, products } = combo;

                  if (products.length === 0) {
                    return (
                      <div className="cash-back-block">
                        <div>
                          Вам доступен cashback
                          <b>
                            {amount}
                            {' '}
руб.
                          </b>
                        </div>
                      </div>
                    );
                  }

                  if (products.length > 0) {
                    return (
                      <div className="cash-back-block">
                        <div>
                          Для начисления cashback
                          {' '}
                          <b>
                            {amount}
                            {' '}
руб.
                          </b>
, убедитесь, что в чеке так же будут позиции:
                          {products.map((prod) => {
                            if (prod.id !== '1C' && prod.id !== '1С') {
                              return (
                                <>
                                  <Link to={`/reload-catalog-form-fill/${prod.id}`}>
                                    {prod.name}
                                  </Link>,
                                </>
                              );
                            }
                            return (
                              <>
                                <span onClick={windowCloseWithNotify} className="link-to-one-c">{prod.name}</span>,
                              </>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                })}
                {!multipleSaleAvailable && (
                  <div className="success-text">
                    <span className="success-label" dangerouslySetInnerHTML={{ __html: finishStepConfig && finishStepConfig.successText ? finishStepConfig.successText : 'Для завершения оформления&nbsp;платежа нажмите кнопку "Перейти в 1С"' }} />
                  </div>
                )}
                <div className="footer-button-wrap">
                  {finishStepConfig && finishStepConfig.buttons && finishStepConfig.buttons.length
                    ? finishStepConfig.buttons.map((button, i, arr) => (
                      <Button
                        key={`${button.label}-${i}`}
                        color={arr.length > 1 && i === 0 ? Button.Color.white : Button.Color.blue}
                        label={button.label}
                        onClick={button.redirect ? () => history.push(button.redirect) : this.onCloseWindowClick}
                      />
                    )) : (
                      <React.Fragment>
                        {multipleSaleAvailable && (
                          <Button key="to-main-page-btn" label="На главную" color={Button.Color.white} onClick={() => history.push(`/catalog/${form.cat}`)} />
                        )}
                        <Button key="to-onec-btn" label="Перейти в 1С" color={Button.Color.blue} onClick={this.onCloseWindowClick} />
                      </React.Fragment>
                    )}
                </div>
              </div>
            )}

            {formError && (
              <div className="payments-phase payments-phase-error status-screen">
                <div className="picture-wrap">
                  <ErrorStatus className="picture-svg" />
                </div>
                <div className="finish-step-title">Произошла ошибка</div>
                <div className="error-text">{formErrorMessage || 'Проверьте правильность введеннных данных'}</div>
                <div className="footer-button-wrap">
                  <Button label="Перейти в 1С" color={Button.Color.white} onClick={this.onCloseWindowClick} />
                  <Button label="Вернуться к форме" color={Button.Color.blue} onClick={this.onErrorBackToFormClick} />
                </div>
              </div>
            )}
          </div>
          <div className="right-fixed-block" ref={ref => this.rightBlock.element = ref}>
            <RightBillingInfo
              catalogFormFillState={this.state}
              outletExtra={user.info.user.outletExtra}
              itogo={this.state.values.full_amount}
              comission={this.state.comission}
              full_amount={this.state.values.full_amount}
              trnRate={this.state.trnRate}
              vskDiscount={this.state.vskDiscount}
              realDiscount={this.state.realDiscount}
              location={location}
            />
            {this.props.tekoCart && this.props.tekoCart.cartList.length > 0 && window.sessionStorage.getItem('cartWasInited') && (
              <div style={{ paddingTop: '16px', display: 'flex', justifyContent: 'center' }}>
                <Button label="Вернуться в корзину" color={Button.Color.blue} size="high" onClick={() => this.props.history.push('/cart')} />
              </div>
            )}
          </div>
        </div>

        <Modal
          backdrop
          active={ui.modalOpened}
          onClickOutside={this.onBackdropClick}
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
          transitionName="ui-modal"
        >
          <div className="modal-close-btn" onClick={this.onRefuseCancelClick}><Close /></div>
          <div className="modal-header">Подтверждение выхода</div>
          <div className="modal-body">Вы действительно хотите покинуть страницу оформления платежа?</div>
          <div className="modal-footer">
            <Button label="Нет" color={Button.Color.white} onClick={this.onRefuseCancelClick} />
            <Button label="Да" color={Button.Color.blue} onClick={this.onConfirmCancelClick} />
          </div>
        </Modal>

        <Modal
          backdrop
          active={vskDmsSuggestModalActive}
          onClickOutside={noop}
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
          transitionName="ui-modal"
        >
          <div className="modal-close-btn" onClick={this.onDeclineVskDmsSuggest}><Close /></div>
          <div className="modal-header">Предложи клиенту подписку на консультационные услуги «Поддержка трудовых переселенцев»</div>
          <div className="modal-body">Добавить подписку?</div>
          <div className="modal-footer">
            <Button label="Нет" color={Button.Color.white} onClick={this.onDeclineVskDmsSuggest} />
            <Button label="Да" color={Button.Color.blue} onClick={this.onConfirmVskDmsSuggest} />
          </div>
        </Modal>
      </div>
    );
  }
}
